import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { automatedActionEpics } from './automatedAction/automatedActionEpics.ts';
import { automatedActionConfigurationEpics } from './automatedActionConfiguration/automatedActionConfigurationEpics.ts';
import { distributionListEpics } from './distributionList/epics.ts';
import { editDistributionListEpics } from './editDistributionList/epics.tsx';
import { editGoalScheduleEpics } from './editGoalSchedule/editGoalScheduleEpics.ts';
import { editInstanceScheduleEpics } from './editInstanceSchedule/editInstanceScheduleEpics.ts';
import { processInstanceChangesSerialEpic } from './epic.processInstanceChangesSerial.ts';
import { epics as formDigitalContentEpics } from './form-digital-content/epics.ts';
import { epics as formEditGoalEpics } from './form-edit-goal/epics.ts';
import { epics as formFileEpics } from './form-file/epics.ts';
import { epics as formInstanceEpics } from './form-instance/epics.ts';
import { epics as formScoreEpics } from './form-score/epics.ts';
import { formActionEpics } from './formAction/formActionEpics.ts';
import { formActionPlanEpics } from './formActionPlan/formActionPlanEpics.ts';
import { formBulkUploadClickThroughEpics } from './formBulkUploadClickThrough/formBulkUploadClickThroughEpics.ts';
import { formBulkUploadDownloadFailedRowsEpics } from './formBulkUploadDownloadFailedRows/formBulkUploadDownloadFailedRowsEpic.ts';
import { formBulkUploadDownloadTemplateEpics } from './formBulkUploadDownloadTemplate/formBulkUploadDownloadTemplateEpics.ts';
import { formBulkUploadImportFileEpics } from './formBulkUploadImportFile/formBulkUploadImportFileEpics.ts';
import { formBulkUploadJobHistoryEpics } from './formBulkUploadJobHistory/formBulkUploadJobHistoryEpics.ts';
import { formInstanceAppealEpics } from './formInstanceAppeal/formInstanceAppealEpics.ts';
import { goalScheduleEpics } from './goalSchedule/goalScheduleEpics.ts';
import { goalsActionsGridEpics } from './goalsActionsGrid/goalsActionsGridEpics.ts';
import { insightEpics } from './insight/insightEpics.ts';
import { instanceScheduleEpics } from './instanceSchedule/instanceScheduleEpics.ts';
import { issueEpics } from './issue/issueEpics.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from './store.ts';

export const formsRootEpic = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  automatedActionConfigurationEpics,
  automatedActionEpics,
  distributionListEpics,
  editDistributionListEpics,
  editGoalScheduleEpics,
  editInstanceScheduleEpics,
  formActionEpics,
  formActionPlanEpics,
  formBulkUploadClickThroughEpics,
  formBulkUploadDownloadFailedRowsEpics,
  formBulkUploadDownloadTemplateEpics,
  formBulkUploadImportFileEpics,
  formBulkUploadJobHistoryEpics,
  formDigitalContentEpics,
  formEditGoalEpics,
  formFileEpics,
  formInstanceAppealEpics,
  formInstanceEpics,
  formScoreEpics,
  goalsActionsGridEpics,
  goalScheduleEpics,
  insightEpics,
  instanceScheduleEpics,
  issueEpics,
  processInstanceChangesSerialEpic,
);

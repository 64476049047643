import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormActionDto } from '@se/data/forms/query/action.ts';
import { FormActionAuditEntry } from '@se/data/forms/query/actionAuditTypes.ts';
import {
  FormActionAnswer,
  FormActionAnswerDropdown,
  FormActionAnswerText,
  FormActionInsight,
  FormActionInstance,
  FormActionIssue,
  FormActionStatus,
  FormActionStatusWithOverdue,
  FormActionTemplateDto,
  FormActionVerificationStatus,
  FormInstanceInsight,
  FormInstanceIssue,
} from '@se/data/forms/types.ts';
import { SimpleEntity } from '@se/data/types.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import moment from 'moment';
import {
  dueDateForDay,
  getDefaultDueByForActions,
} from '../../util/util.instance.ts';
import { toDatesDisplayValue, toGoalDatesDisplayValue } from './util.ts';

export type FormAction = {
  answers?: FormActionAnswer[];
  answerAutomatedActionId?: string;
  assignedTo?: SimpleEntity;
  description?: string;
  dueBy?: string;
  goalId?: string;
  id: string;
  instanceId?: string;
  insight?: FormInstanceInsight;
  issue?: FormInstanceIssue;
  note?: string;
  status: FormActionStatusWithOverdue;
  verificationNotes?: string;
  verificationStatus?: FormActionVerificationStatus;
  createdAt?: string;
  completedAt?: string;
};

export type DraftFormActionChanges = {
  action: Partial<FormAction>;
  answers: FormActionAnswer[];
};

export type FormActionAuditPerson = {
  id: string;
  name: string;
};

export type FormActionAuditPayload = {
  key: string;
  value: string;
};

type FormActionGoal = {
  goalId: string;
  category?: string;
  description: string;
  status: FormActionStatusWithOverdue;
  statusDisplayValue: string;
  datesDisplayValue?: string;
  createdAt: string;
  completedAt?: string;
  dueBy?: string;
  assignedTo?: {
    id: string;
    name: string;
  };
};

type FormActionInstanceSubject = {
  id: string;
  label: string;
  type: 'PERSON' | 'TEAM';
};

export type FormActionSource =
  | { type: 'FORM'; instanceId: string }
  | { type: 'DASHBOARD'; gridKey: string }
  | { type: 'GOALS_AND_ACTIONS_GRID' };

export type FormActionPermissions = {
  edit: boolean;
  cancel: boolean;
  revert: boolean;
  close: boolean;
  verify: boolean;
};

export type FormActionTemplate = FormActionTemplateDto;

export type FormActionState = {
  id?: string;
  actionTemplate?: FormActionTemplate;
  existingAction?: FormAction;
  goal?: FormActionGoal;
  formInstance?: FormActionInstance;
  formInstanceSubject?: FormActionInstanceSubject;
  draftChanges: DraftFormActionChanges;
  errors: {
    action: {
      description?: string;
      dueBy?: string;
      assignedTo?: string;
      note?: string;
      verificationNotes?: string;
    };
    answers: Array<{ questionId: string; error?: string }>;
  };
  insight?: FormActionInsight;
  issue?: FormActionIssue;
  instanceInsights?: FormInstanceInsight[];
  instanceIssues?: FormInstanceIssue[];
  permissions?: FormActionPermissions;
  isLoading: boolean;
  isSaving: boolean;
  isShowing: boolean;
  source?: FormActionSource;
  audit: {
    actionId?: string;
    isLoading: boolean;
    loadingError: boolean;
    entries: FormActionAuditEntry[];
  };
};

const DEFAULT_STATE: FormActionState = {
  errors: { action: {}, answers: [] },
  draftChanges: { action: {}, answers: [] },
  isLoading: false,
  isSaving: false,
  isShowing: false,
  audit: {
    isLoading: false,
    loadingError: false,
    entries: [],
  },
};

const slice = createSlice({
  name: 'libs/forms/action',
  initialState: DEFAULT_STATE,
  reducers: {
    hide(state) {
      state.isShowing = false;
    },
    newDraft(
      state,
      action: PayloadAction<{
        actionId: string;
        goalId?: string;
        issue?: FormInstanceIssue;
        insight?: FormInstanceInsight;
        instanceInsights?: FormInstanceInsight[];
        instanceIssues?: FormInstanceIssue[];
        assignedTo?: SimpleEntity;
        answerAutomatedActionId?: string;
        formInstanceSubject?: {
          id: string;
          label: string;
          type: 'PERSON' | 'TEAM';
        };
        openedFromFormInstanceId: string;
        description?: string;
      }>,
    ) {
      state.errors = { action: {}, answers: [] };
      state.draftChanges.action = {
        id: action.payload.actionId,
        assignedTo: action.payload.assignedTo,
        answerAutomatedActionId: action.payload.answerAutomatedActionId,
        goalId: action.payload.goalId,
        issue: action.payload.issue,
        insight: action.payload.insight,
        status: 'Open',
        description: action.payload.description,
      };

      state.draftChanges.answers = [];
      state.id = action.payload.actionId;
      state.actionTemplate = undefined;
      state.existingAction = undefined;
      state.formInstance = undefined;
      state.formInstanceSubject = action.payload.formInstanceSubject;
      state.goal = undefined;
      state.instanceInsights = action.payload.instanceInsights;
      state.instanceIssues = action.payload.instanceIssues;
      state.insight = undefined;
      state.issue = undefined;
      state.permissions = {
        edit: true,
        cancel: false,
        revert: false,
        close: false,
        verify: false,
      };
      state.isShowing = true;
      state.isSaving = false;
      state.isLoading = true;
      state.source = {
        type: 'FORM',
        instanceId: action.payload.openedFromFormInstanceId,
      };
    },
    loadedActionTemplateForNewDraft(
      state,
      action: PayloadAction<{
        module: ModuleType;
        actionTemplate?: FormActionTemplateDto;
        timezone: string;
      }>,
    ) {
      const { module, actionTemplate, timezone } = action.payload;

      state.isLoading = false;
      state.actionTemplate = actionTemplate;
      state.draftChanges.action.dueBy = getDefaultDueByForActions(
        module,
        timezone,
        actionTemplate?.defaultDueByExpression,
      );
    },
    load(
      state,
      action: PayloadAction<{
        actionId: string;
        source: FormActionSource;
      }>,
    ) {
      state.errors = { action: {}, answers: [] };
      state.draftChanges.action = {};
      state.draftChanges.answers = [];
      state.actionTemplate = undefined;
      state.existingAction = undefined;
      state.formInstance = undefined;
      state.formInstanceSubject = undefined;
      state.goal = undefined;
      state.instanceIssues = undefined;
      state.instanceInsights = undefined;
      state.insight = undefined;
      state.issue = undefined;
      state.permissions = undefined;
      state.isLoading = true;
      state.isSaving = false;
      state.isShowing = true;
      state.source = action.payload.source;
      state.audit.actionId = undefined;
    },
    loadHistory(state, action: PayloadAction<{ actionId: string }>) {
      if (
        state.audit.actionId === action.payload.actionId &&
        !state.audit.loadingError
      )
        return;

      state.audit.actionId = action.payload.actionId;
      state.audit.isLoading = true;
      state.audit.loadingError = false;
      state.audit.entries = [];
    },
    loadHistoryError(state, action: PayloadAction<{ actionId: string }>) {
      if (state.audit.actionId !== action.payload.actionId) return;

      state.audit.isLoading = false;
      state.audit.loadingError = true;
    },
    loadedHistory(
      state,
      action: PayloadAction<{
        actionId: string;
        entries: FormActionAuditEntry[];
      }>,
    ) {
      if (state.audit.actionId !== action.payload.actionId) return;

      state.audit.isLoading = false;
      state.audit.loadingError = false;
      state.audit.entries = action.payload.entries;
    },
    loaded(
      state,
      action: PayloadAction<{
        actionId: string;
        formAction: FormActionDto;
        timezone: string;
      }>,
    ) {
      const formAction = action.payload.formAction;
      const timezone = action.payload.timezone;
      state.isLoading = false;
      state.id = formAction.id;

      state.existingAction = {
        id: formAction.id,
        answers: formAction.answers,
        answerAutomatedActionId: formAction.answerAutomatedActionId,
        assignedTo: formAction.assignedTo,
        description: formAction.description,
        note: formAction.note,
        dueBy: formAction.dueBy,
        status: formAction.status,
        goalId: formAction.goalId,
        insight: formAction.insight?.insight
          ? {
              id: formAction.insight.id,
              label: formAction.insight.insight.label,
              formSectionName: formAction.insight.insight.formSectionName,
              classifications: formAction.insight.insight.classifications ?? [],
              type: formAction.insight.insight.type,
            }
          : undefined,
        issue: formAction.issue?.issue
          ? {
              id: formAction.issue.id,
              label: formAction.issue.issue.label,
              issueCoachingRequired:
                formAction.issue.issue.issueCoachingRequired,
              formSectionName: formAction.issue.issue.formSectionName,
            }
          : undefined,
        instanceId: formAction.formInstance?.id,
        verificationNotes: formAction.verification?.notes,
        verificationStatus: formAction.verification?.status,
      };
      state.draftChanges.action = {};
      state.permissions = formAction.permissions;
      state.actionTemplate = formAction.actionTemplate;
      state.instanceIssues = formAction.instanceIssues;
      state.instanceInsights = formAction.instanceInsights;

      const formActionInsight = formAction.insight?.insight;
      if (formActionInsight) {
        state.insight = {
          id: formActionInsight.id,
          type: formActionInsight.type,
          label: formActionInsight.label,
          notes: formActionInsight.notes,
          formSectionName: formActionInsight.formSectionName,
          formQuestionText: formActionInsight.formQuestionText,
          classifications: formActionInsight.classifications ?? [],
          answer: formActionInsight.answer
            ? {
                id: formActionInsight.answer.id,
                displayValue: formActionInsight.answer.displayValue,
                value: formActionInsight.answer.value,
              }
            : undefined,
          instance: formAction.insight.instance
            ? {
                id: formAction.insight.instance.id,
                subject: formAction.insight.instance.subject
                  ? {
                      id: formAction.insight.instance.subject.id,
                      name: formAction.insight.instance.subject.name,
                      type: formAction.insight.instance.subject.type,
                    }
                  : undefined,
                status: formAction.insight.instance.status,
                statusDisplayValue: str.titleCase(
                  str.humanize(formAction.insight.instance.status),
                ),
                reportingDate: formAction.insight.instance.reportingDate,
                dueBy: formAction.insight.instance.dueBy,
                datesDisplayValue: toDatesDisplayValue(
                  formAction.insight.instance.status,
                  timezone,
                  formAction.insight.instance.reportingDate,
                  formAction.insight.instance.dueBy,
                ),
                template: {
                  id: formAction.insight.instance.template.id,
                  name: formAction.insight.instance.template.name,
                  category: formAction.insight.instance.template.category,
                },
              }
            : undefined,
        };
      }

      const formActionIssue = formAction.issue?.issue;
      if (formActionIssue) {
        state.issue = {
          id: formActionIssue.id,
          label: formActionIssue.label,
          notes: formActionIssue.notes,
          issueCoachingRequired: formActionIssue.issueCoachingRequired,
          formSectionName: formActionIssue.formSectionName,
          formQuestionText: formActionIssue.formQuestionText,
          classifications: formActionIssue.classifications ?? [],
          answer: formActionIssue.answer
            ? {
                id: formActionIssue.answer.id,
                displayValue: formActionIssue.answer.displayValue,
                value: formActionIssue.answer.value,
              }
            : undefined,
          instance: formAction.issue.instance
            ? {
                id: formAction.issue.instance.id,
                subject: formAction.issue.instance.subject
                  ? {
                      id: formAction.issue.instance.subject.id,
                      name: formAction.issue.instance.subject.name,
                      type: formAction.issue.instance.subject.type,
                    }
                  : undefined,
                status: formAction.issue.instance.status,
                statusDisplayValue: str.titleCase(
                  str.humanize(formAction.issue.instance.status),
                ),
                reportingDate: formAction.issue.instance.reportingDate,
                dueBy: formAction.issue.instance.dueBy,
                datesDisplayValue: toDatesDisplayValue(
                  formAction.issue.instance.status,
                  timezone,
                  formAction.issue.instance.reportingDate,
                  formAction.issue.instance.dueBy,
                ),
                template: {
                  id: formAction.issue.instance.template.id,
                  name: formAction.issue.instance.template.name,
                  category: formAction.issue.instance.template.category,
                },
              }
            : undefined,
        };
      }

      const formGoal = formAction.goal;
      if (formGoal) {
        state.goal = {
          goalId: formGoal.id,
          category: formGoal.goalCategory?.name,
          description: formGoal.description,
          status: formGoal.status,
          statusDisplayValue: str.titleCase(
            str.humanize(formAction.goal.status),
          ),
          createdAt: formGoal.createdAt,
          completedAt: formGoal.completedAt,
          dueBy: formGoal.dueBy,
          datesDisplayValue: toGoalDatesDisplayValue(
            formGoal.createdAt,
            timezone,
            formGoal.dueBy,
            formGoal.completedAt,
          ),
          assignedTo: formGoal.assignedTo
            ? {
                id: formGoal.assignedTo.id,
                name: formGoal.assignedTo.name,
              }
            : undefined,
        };
      }

      const formInstance = formAction.formInstance;
      if (formInstance) {
        state.formInstance = {
          ...formInstance,
          statusDisplayValue: str.titleCase(str.humanize(formInstance.status)),
          datesDisplayValue: toDatesDisplayValue(
            formInstance.status,
            timezone,
            formInstance.reportingDate,
            formInstance.dueBy,
          ),
        };
        state.formInstanceSubject = formInstance.subject
          ? {
              id: formInstance.subject.id,
              label: formInstance.subject.name,
              type: formInstance.subject.type === 'Person' ? 'PERSON' : 'TEAM',
            }
          : undefined;
      }
    },
    loadError(state) {
      state.isLoading = false;
      state.isShowing = false;
    },
    updateAssignedTo(state, action: PayloadAction<{ to: SimpleEntity }>) {
      const to = action.payload.to;
      state.draftChanges.action.assignedTo =
        state.existingAction?.assignedTo?.id !== to.id ? to : undefined;
      state.errors.action.assignedTo = undefined;
    },
    updateDescription(state, action: PayloadAction<{ to: string }>) {
      const to = action.payload.to;
      state.draftChanges.action.description =
        state.existingAction?.description !== to ? to : undefined;
      state.errors.action.description = undefined;
    },
    updateDueBy(
      state,
      action: PayloadAction<{ to: string; timezone: string }>,
    ) {
      const to = action.payload.to;
      const timezone = action.payload.timezone;
      const dueByForDate = dueDateForDay(to, timezone).toISOString();

      state.draftChanges.action.dueBy =
        state.existingAction?.dueBy !== dueByForDate ? dueByForDate : undefined;
      state.errors.action.dueBy = undefined;
    },
    clearInsight(state) {
      state.draftChanges.action.insight =
        state.existingAction?.insight !== undefined ? null : undefined;
    },
    updateInsight(state, action: PayloadAction<{ to: FormInstanceInsight }>) {
      const to = action.payload.to;
      state.draftChanges.action.insight =
        state.existingAction?.insight?.id !== to.id ? to : undefined;
    },
    clearIssue(state) {
      state.draftChanges.action.issue =
        state.existingAction?.issue !== undefined ? null : undefined;
    },
    updateIssue(state, action: PayloadAction<{ to: FormInstanceIssue }>) {
      const to = action.payload.to;
      state.draftChanges.action.issue =
        state.existingAction?.issue?.id !== to.id ? to : undefined;
    },
    updateNote(state, action: PayloadAction<{ to: string }>) {
      const to = action.payload.to;

      if (!state.existingAction?.note && !to) {
        state.draftChanges.action.note = undefined;
      } else {
        state.draftChanges.action.note =
          state.existingAction?.note !== to ? to : undefined;
      }
      state.errors.action.note = undefined;
    },
    updateStatus(state, action: PayloadAction<{ to: FormActionStatus }>) {
      const to = action.payload.to;
      state.draftChanges.action.status =
        state.existingAction?.status !== to ? to : undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    save(state, action: PayloadAction<{ actionId: string }>) {
      state.isSaving = true;
    },
    saved(
      state,
      action: PayloadAction<{
        actionId: string;
        initial?: FormAction;
        isSuccess: boolean | 'PARTIAL_SUCCESS';
        operation: 'CREATE' | 'UPDATE';
        source: FormActionSource;
        updated?: FormAction;
        instanceId?: string;
      }>,
    ) {
      if (action.payload.isSuccess !== false) state.isShowing = false;
      if (action.payload.isSuccess === false) state.isSaving = false;
    },
    validationFailed(state) {
      state.isSaving = false;
    },
    answerError(
      state,
      action: PayloadAction<{ questionId: string; error: string }>,
    ) {
      const existingAnswerError = state.errors.answers.find(
        (a) => a.questionId === action.payload.questionId,
      );
      if (existingAnswerError) {
        existingAnswerError.error = action.payload.error;
      } else {
        state.errors.answers.push({
          questionId: action.payload.questionId,
          error: action.payload.error,
        });
      }
    },
    noteError(state, action: PayloadAction<{ error: string }>) {
      state.errors.action.note = action.payload.error;
    },
    dueByError(state, action: PayloadAction<{ error: string }>) {
      state.errors.action.dueBy = action.payload.error;
    },
    assignedToError(state, action: PayloadAction<{ error: string }>) {
      state.errors.action.assignedTo = action.payload.error;
    },
    descriptionError(state, action: PayloadAction<{ error: string }>) {
      state.errors.action.description = action.payload.error;
    },
    updateVerificationNotes(state, action: PayloadAction<{ to: string }>) {
      const to = action.payload.to;
      state.draftChanges.action.verificationNotes =
        state.existingAction?.verificationNotes !== to ? to : undefined;
      state.errors.action.verificationNotes = undefined;
    },
    verificationNotesError(state, action: PayloadAction<{ error: string }>) {
      state.errors.action.verificationNotes = action.payload.error;
    },
    updateVerificationStatus(
      state,
      action: PayloadAction<{ to: FormActionVerificationStatus }>,
    ) {
      const to = action.payload.to;
      state.draftChanges.action.verificationStatus =
        state.existingAction?.verificationStatus !== to ? to : undefined;
    },
    updateDropdownAnswer(
      state,
      action: PayloadAction<{ questionId: string; selectedId: string }>,
    ) {
      const questionId = action.payload.questionId;
      const selectedId = action.payload.selectedId;
      const existingAnswer = state.existingAction?.answers?.find(
        (answer): answer is FormActionAnswerDropdown =>
          answer.questionId === questionId && answer.type === 'Dropdown',
      );
      const draftAnswer = state.draftChanges.answers.find(
        (answer): answer is FormActionAnswerDropdown =>
          answer.questionId === questionId && answer.type === 'Dropdown',
      );

      state.errors.answers = state.errors.answers.filter(
        (error) => error.questionId !== questionId,
      );

      const isUnchangedSetValue =
        existingAnswer?.selectedOptionIds?.[0] === selectedId;

      if (isUnchangedSetValue) {
        state.draftChanges.answers = state.draftChanges.answers.filter(
          (answer) => answer.questionId !== questionId,
        );
        return;
      }

      if (!draftAnswer) {
        state.draftChanges.answers.push({
          questionId,
          type: 'Dropdown',
          selectedOptionIds: [selectedId],
        });
      } else {
        draftAnswer.selectedOptionIds = [selectedId];
      }
    },
    updateTextAnswer(
      state,
      action: PayloadAction<{ questionId: string; to: string }>,
    ) {
      const questionId = action.payload.questionId;
      const to = action.payload.to;
      const existingAnswer = state.existingAction?.answers?.find(
        (answer): answer is FormActionAnswerText =>
          answer.questionId === questionId,
      );
      const draftAnswer = state.draftChanges.answers.find(
        (answer): answer is FormActionAnswerText =>
          answer.questionId === questionId,
      );

      state.errors.answers = state.errors.answers.filter(
        (error) => error.questionId !== questionId,
      );

      const isUnchangedEmptyValue = !existingAnswer?.value && !to;
      const isUnchangedSetValue = existingAnswer?.value === to;

      if (isUnchangedEmptyValue || isUnchangedSetValue) {
        state.draftChanges.answers = state.draftChanges.answers.filter(
          (answer) => answer.questionId !== questionId,
        );
        return;
      }

      if (!draftAnswer) {
        state.draftChanges.answers.push({
          questionId,
          type: 'Text',
          value: to,
        });
      } else {
        draftAnswer.value = to;
      }
    },
  },
});

export const analytics = (
  action: ReduxAction,
  trackEvent: (eventIdentifier: string, payload: object) => AnalyticsEvent,
) => {
  if (slice.actions.loaded.match(action))
    return [trackEvent('action_view', { actionId: action.payload.actionId })];

  if (
    slice.actions.saved.match(action) &&
    action.payload.isSuccess &&
    action.payload.operation === 'CREATE'
  )
    return [trackEvent('action_create', { actionId: action.payload.actionId })];

  if (
    slice.actions.saved.match(action) &&
    action.payload.isSuccess &&
    action.payload.operation === 'UPDATE'
  ) {
    const { actionId, initial, updated } = action.payload;

    const isCompleted =
      initial.status !== 'Completed' && updated.status === 'Completed';

    const isNoteUpdated = initial.note !== updated.note;

    return [
      trackEvent('action_save', { actionId }),
      isCompleted &&
        trackEvent('action_completed', {
          actionId,
          completedOnTime: initial.dueBy
            ? moment().isSameOrBefore(moment(initial.dueBy))
            : true,
        }),
      isNoteUpdated && trackEvent('action_add_note', { actionId }),
    ].filter(Boolean);
  }
};

export const {
  answerError,
  assignedToError,
  clearInsight,
  clearIssue,
  descriptionError,
  dueByError,
  hide,
  load,
  loaded,
  loadedActionTemplateForNewDraft,
  loadedHistory,
  loadError,
  loadHistory,
  loadHistoryError,
  newDraft,
  noteError,
  save,
  saved,
  updateAssignedTo,
  updateDescription,
  updateDropdownAnswer,
  updateDueBy,
  updateInsight,
  updateIssue,
  updateNote,
  updateStatus,
  updateTextAnswer,
  updateVerificationNotes,
  updateVerificationStatus,
  validationFailed,
  verificationNotesError,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormActionState;

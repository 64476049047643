import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import {
  addInsightForOptionsAnswerWithInsightEpic,
  isLoadingOnExistingInsightForAnswerIdChangedEpic,
  removeInsightForOptionsAnswerWithoutInsightEpic,
  removeInsightOnOptionsAnswerHiddenEpic,
  saveCopiedInsightDetailsOnOptionsAnswerSavedEpic,
} from './epic.addRemoveInsight.ts';
import { loadedInsightsOnInstanceLoadedEpic } from './epic.loadedInsights.ts';

export const insightEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  addInsightForOptionsAnswerWithInsightEpic,
  isLoadingOnExistingInsightForAnswerIdChangedEpic,
  loadedInsightsOnInstanceLoadedEpic,
  removeInsightForOptionsAnswerWithoutInsightEpic,
  removeInsightOnOptionsAnswerHiddenEpic,
  saveCopiedInsightDetailsOnOptionsAnswerSavedEpic,
);

import { FormIssueInsightAnswerDefinition } from '@se/data/forms/types.ts';
import { OptionsAnswerDefinition } from '../parse/types/options.types.ts';
import { FormLineById } from '../redux/form-instance/types.ts';
import { ANSWER_TYPE_LABELS } from './constants.ts';

export const distinctIssueInsightClassifications = (
  answers: OptionsAnswerDefinition[],
) => {
  if (!answers?.length) return [];

  const classifications = new Set(
    answers
      .map((answer) => {
        if (answer.issue) return answer.issue.classification?.options;
        if (answer.insight) return answer.insight.classification?.options;
      })
      .filter(Boolean)
      .flat(),
  );
  return [...classifications].sort();
};

export const getIssueInsightQuestions = (
  answers: OptionsAnswerDefinition[],
  id: string,
  label: string,
  parentHeadingId: string,
) => {
  if (!answers?.length) return [];
  if (!answers.some((answer) => Boolean(answer.issue || answer.insight)))
    return [];

  const notesQuestion = {
    id: `${id} Notes`,
    label: `${label} Notes`,
    isSelected: false,
    isHiddenByDefault: true,
    parentHeadingId,
    answerOptions: [ANSWER_TYPE_LABELS.text],
  };

  const classifications = distinctIssueInsightClassifications(answers);
  if (!classifications.length) return [notesQuestion];

  return [
    notesQuestion,
    {
      id: `${id} Classifications`,
      label: `${label} Classifications`,
      isSelected: false,
      isHiddenByDefault: true,
      parentHeadingId,
      answerOptions: [ANSWER_TYPE_LABELS.multiSelect, ...classifications],
    },
  ];
};

export const getIssueInsightsAnswerDefinitions = (
  formLines: FormLineById,
): Record<string, FormIssueInsightAnswerDefinition[]> =>
  Object.entries(formLines).reduce((acc, [questionKey, line]) => {
    if (line.type !== 'optionsAnswer') return acc;

    const optionsWithIssuesInsights = (line.options ?? []).filter((o) =>
      Boolean(o.issue || o.insight),
    );
    if (!optionsWithIssuesInsights.length) return acc;

    const issueInsightDefinitions: FormIssueInsightAnswerDefinition[] =
      optionsWithIssuesInsights.flatMap((optionAnswer) => {
        const issueInsight = optionAnswer.issue ?? optionAnswer.insight;

        return {
          type: optionAnswer.issue ? 'Issue' : 'Insight',
          optionId: optionAnswer.id,
          label: issueInsight.label,
          notes: issueInsight.notesDefinition,
          classification: issueInsight.classificationDefinition,
        };
      });

    return {
      ...acc,
      [questionKey]: issueInsightDefinitions,
    };
  }, {});

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { INTERSECTION_OBSERVER_DEFAULTS } from '@seeeverything/ui.primitives/src/common/constants.ts';
import { useViewport } from '@seeeverything/ui.primitives/src/hooks/useViewport.ts';
import { useCallback, useMemo } from 'react';
import { useLoadFormActionPlan } from '../../hooks/useLoadFormActionPlan.ts';
import { issueSlice } from '../../redux/issue/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormListItem } from '../FormListItem/FormListItem.tsx';
import { IssueGroupSkeleton } from '../Issue/IssueGroupSkeleton.tsx';
import { SpeechBlockContainer } from '../SpeechBlock/SpeechBlockContainer.tsx';
import { Empty } from './components/Empty.tsx';
import { IssueCoachingGroup } from './components/IssueCoachingGroup.tsx';

export type IssueCoachingPlanContainerProps = {
  instanceId: string;
  isPdfExport: boolean;
};

export const IssueCoachingPlanContainer: React.FC<
  IssueCoachingPlanContainerProps
> = ({ instanceId, isPdfExport }) => {
  const dispatch = useFormsDispatch();

  const [viewportRef, inViewport] = useViewport(INTERSECTION_OBSERVER_DEFAULTS);

  const isLoading = useFormsSelector(
    (state) =>
      Boolean(state.formInstance.instances[instanceId]?.subject) &&
      (state.formIssue.isLoading ||
        state.formActionPlan.actionPlan.issueCoaching?.isLoading !== false),
  );

  const handleLoadActionPlan = useLoadFormActionPlan({
    instanceId,
    lineId: 'issueCoaching',
    includeCategories: ['Issue Coaching Actions'],
    hasSections: false,
    inViewport,
  });

  const guidanceText = useFormsSelector(
    (state) =>
      state.tenantState.tenant?.locale.forms.closeTheLoop
        .issueCoachingPlanGuidanceScript,
  );

  const formIssues = useFormsSelector((state) => state.formIssue.issues);

  const issuesWithCoachingRequired = useMemo(
    () =>
      formIssues.filter((issue) => issue.coaching.requirement === 'MANDATORY'),
    [formIssues],
  );

  const issuesWithCoachingOptional = useMemo(
    () =>
      formIssues.filter((issue) => issue.coaching.requirement !== 'MANDATORY'),
    [formIssues],
  );

  const emptyReason = useFormsSelector((state) => {
    if (state.formIssue.loadError) return 'LOAD_ERROR';
    if (!state.formInstance.instances?.[instanceId]?.subject)
      return 'SUBJECT_UNASSIGNED';
    if (!state.formIssue.canView) return 'INSUFFICIENT_PERMISSIONS';
    return 'NO_ISSUES_COACHING_PLAN';
  });

  const reloadAnswerIssues = useCallback(() => {
    setTimeout(() => {
      dispatch(issueSlice.loadIssues());
      dispatch(issueSlice.loadIssueCoachingPlan({ instanceId }));
      handleLoadActionPlan();
    }, 225);
  }, [dispatch, handleLoadActionPlan, instanceId]);

  const isEmpty =
    !issuesWithCoachingRequired.length && !issuesWithCoachingOptional.length;

  const elEmpty = isEmpty && !isLoading && (
    <Empty reason={emptyReason} onReload={reloadAnswerIssues} />
  );

  const elContinuousImprovementGuidance = !isEmpty && (
    <>
      <SpeechBlockContainer
        id={`issueCoachingPlan-continuousImprovementGuidance`}
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        markdown={guidanceText}
      />
    </>
  );

  const showBullet = !isPdfExport && !isEmpty && !isLoading;

  return (
    <>
      <FormListItem
        id={'issueCoachingPlan'}
        key={'issueCoachingPlan'}
        bullet={'none'}
        marginTop={20}
        marginBottom={0}
        style={styles.continuousImprovement}
      >
        <div css={styles.issues} ref={viewportRef}>
          {elEmpty}
          {elContinuousImprovementGuidance}
        </div>
        {isLoading && <IssueGroupSkeleton />}
      </FormListItem>
      {!isLoading && Boolean(issuesWithCoachingRequired?.length) && (
        <FormListItem
          id={'issueCoachingPlan-coachingRequired'}
          key={'issueCoachingPlan-coachingRequired'}
          bullet={showBullet ? 'block' : 'none'}
          bulletTop={-12}
          bulletLeft={0}
          marginTop={60}
          marginBottom={15}
          style={styles.continuousImprovement}
        >
          <IssueCoachingGroup
            instanceId={instanceId}
            isPdfExport={isPdfExport}
            issues={issuesWithCoachingRequired}
            requirement={'MANDATORY'}
          />
        </FormListItem>
      )}
      {!isLoading && Boolean(issuesWithCoachingOptional?.length) && (
        <FormListItem
          id={'issueCoachingPlan-coachingRecommended'}
          key={'issueCoachingPlan-coachingRecommended'}
          bullet={showBullet ? 'block' : 'none'}
          bulletTop={-12}
          bulletLeft={0}
          marginTop={60}
          marginBottom={15}
          style={styles.continuousImprovement}
        >
          <IssueCoachingGroup
            requirement={'RECOMMENDED'}
            instanceId={instanceId}
            isPdfExport={isPdfExport}
            issues={issuesWithCoachingOptional}
          />
        </FormListItem>
      )}
    </>
  );
};

const styles = {
  issues: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  }),
  continuousImprovement: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  }),
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult, SimpleEntity } from '../../types.ts';
import {
  FormActionAnswer,
  FormActionAnswerDropdown,
  FormActionAnswerText,
  FormActionInsight,
  FormActionInstance,
  FormActionIssue,
  FormActionStatusWithOverdue,
  FormActionTemplateDto,
  FormActionVerificationStatus,
  FormGoalStatus,
  FormInstanceInsight,
  FormInstanceIssue,
  FormInstanceStatus,
} from '../types.ts';
import { mapFormActionTemplate } from './actionTemplateUtil.ts';

export type FormActionDto = {
  id: string;
  createdAt: string;
  completedAt?: string;
  answerAutomatedActionId?: string;
  formInstance: {
    id: string;
    template: {
      id: string;
      name: string;
      category: SimpleEntity;
    };
    subject: { id: string; name: string; type: 'Person' | 'Team' };
    status: FormInstanceStatus;
    reportingDate: string;
    dueBy: string;
  };
  assignedTo: SimpleEntity;
  insight?: {
    id: string;
    createdInInstanceId: string;
    instance?: FormActionInstance;
    insight?: FormActionInsight;
  };
  issue?: {
    id: string;
    createdInInstanceId: string;
    instance?: FormActionInstance;
    issue?: FormActionIssue;
  };
  instanceInsights?: FormInstanceInsight[];
  instanceIssues?: FormInstanceIssue[];
  goalId?: string;
  goal?: {
    id: string;
    description: string;
    goalCategory?: {
      id: string;
      name: string;
    };
    assignedTo: {
      id: string;
      name: string;
    };
    status: FormGoalStatus;
    createdAt: string;
    completedAt?: string;
    dueBy?: string;
  };
  description: string;
  note: string;
  status: FormActionStatusWithOverdue;
  dueBy: string;
  permissions: {
    edit: boolean;
    cancel: boolean;
    revert: boolean;
    close: boolean;
    verify: boolean;
  };
  verification?: {
    notes?: string;
    status: FormActionVerificationStatus;
  };
  answers?: FormActionAnswer[];
  actionTemplate?: FormActionTemplateDto;
};

export const getFormAction = async (
  client: IGraphQLClient,
  actionId: string,
): Promise<QueryResult<FormActionDto>> => {
  try {
    const response = await client.query<{
      forms: { formAction: FormActionDto };
    }>({
      query: gql`
        query FormAction($actionId: ID!) {
          forms {
            formAction(id: $actionId) {
              id
              createdAt
              completedAt
              answerAutomatedActionId
              formInstance {
                id
                status
                dueBy
                reportingDate
                template {
                  id
                  name
                  category {
                    id
                    name
                  }
                }
                subject {
                  id
                  name
                  type
                }
              }
              assignedTo {
                id
                name
              }
              issueId
              insight {
                id
                createdInInstanceId
                instance {
                  id
                  subject {
                    id
                    name
                    type
                  }
                  status
                  reportingDate
                  dueBy
                  template {
                    id
                    name
                  }
                }
                insight {
                  id
                  label
                  notes
                  type
                  formSectionName
                  formQuestionText
                  classifications
                  answer {
                    id
                    displayValue
                    value
                  }
                }
              }
              issue {
                id
                createdInInstanceId
                instance {
                  id
                  subject {
                    id
                    name
                    type
                  }
                  status
                  reportingDate
                  dueBy
                  template {
                    id
                    name
                  }
                }
                issue {
                  id
                  label
                  notes
                  issueCoachingRequired
                  formSectionName
                  formQuestionText
                  classifications
                  answer {
                    id
                    displayValue
                    value
                  }
                }
              }
              instanceInsights {
                id
                label
                formSectionName
                type
                classifications
              }
              instanceIssues {
                id
                label
                issueCoachingRequired
                formSectionName
              }
              goalId
              goal {
                id
                description
                goalCategory {
                  id
                  name
                }
                assignedTo {
                  id
                  name
                }
                status
                createdAt
                completedAt
                dueBy
              }
              description
              note
              status
              dueBy
              verification {
                notes
                status
              }
              permissions {
                cancel
                close
                edit
                revert
                verify
              }
              answers {
                questionId
                value
                selectedOptionIds
              }
              actionTemplate {
                id
                questions {
                  id
                  label
                  type
                  isRequired
                  position {
                    rowIndex
                    columnIndex
                  }
                  options {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      `,
      variables: { actionId },
      fetchPolicy: 'network-only',
    });

    const formAction = response.data.forms.formAction;
    if (!formAction) {
      log.error(`Failed to retrieve form action ${actionId}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        ...formAction,
        actionTemplate: mapFormActionTemplate(formAction.actionTemplate),
        answers: mapFormActionAnswers(
          formAction.answers,
          formAction.actionTemplate,
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form action ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

const mapFormActionAnswers = (
  answers?: FormActionAnswer[],
  actionTemplate?: FormActionTemplateDto,
): FormActionAnswer[] => {
  if (!answers?.length) return [];
  if (!actionTemplate) return [];

  return answers
    .map((answer): FormActionAnswer => {
      const question = actionTemplate.questions.find(
        (q) => q.id === answer.questionId,
      );
      if (!question) return;

      if (question.type === 'Dropdown')
        return {
          questionId: answer.questionId,
          type: 'Dropdown',
          selectedOptionIds: (answer as FormActionAnswerDropdown)
            .selectedOptionIds,
        };

      if (question.type === 'Text')
        return {
          questionId: answer.questionId,
          type: 'Text',
          value: (answer as FormActionAnswerText).value,
        };
    })
    .filter(Boolean);
};

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CheckboxGroup,
  CheckboxGroupEvent,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/CheckboxGroup.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import Transition from '@seeeverything/ui.primitives/src/components/Transition/Transition.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useMemo } from 'react';
import { issueSlice } from '../../../redux/issue/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { OptionAnswerIssue } from '../../../types/types.ts';
import { TextAnswer } from '../../TextAnswer/TextAnswer.tsx';

export type IssueDetailsProps = {
  answerId: string;
  instanceId: string;
  issueDefinition?: OptionAnswerIssue;
};

export const IssueDetails: React.FC<IssueDetailsProps> = ({
  answerId,
  instanceId,
  issueDefinition,
}) => {
  const dispatch = useFormsDispatch();

  const issueClassifications = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.classifications ?? [],
  );

  const selectedClassifications = useMemo(
    () =>
      issueClassifications.map((classification) => ({
        id: classification.value,
        checked: true,
      })) ?? [],
    [issueClassifications],
  );

  const notesValue = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.notes,
  );

  const notesError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.errors.compliance.notes,
  );

  const classificationsError = useFormsSelector(
    (state) =>
      state.formIssue.issues.find((issue) => issue.answerId === answerId)
        ?.errors.compliance.classifications,
  );

  const instanceCanEdit = useFormsSelector((state) =>
    Boolean(state.formInstance.instances?.[instanceId]?.permissions.edit),
  );

  const questionKey = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance?.answers) return;

    return Object.values(instance.answers).find(
      (answer) => answer?.id === answerId,
    )?.lineId;
  });

  const handleClassificationChange = useCallback(
    (e: CheckboxGroupEvent) => {
      const isAdd = Boolean(e.to);
      return isAdd
        ? dispatch(
            issueSlice.addClassification({
              answerId,
              instanceId,
              classificationValue: e.id,
              itemIndex: e.index,
              concurrencyId: questionKey,
            }),
          )
        : dispatch(
            issueSlice.deleteClassification({
              answerId,
              instanceId,
              classificationValue: e.id,
              concurrencyId: questionKey,
            }),
          );
    },
    [dispatch, answerId, instanceId, questionKey],
  );

  const handleNoteChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setNotes({
          answerId,
          instanceId,
          notes: to,
          concurrencyId: questionKey,
        }),
      );
    },
    [dispatch, answerId, instanceId, questionKey],
  );

  const classificationOptions = useMemo(
    () =>
      issueDefinition?.classificationDefinition?.options?.map((option) => ({
        id: option,
        label: option,
      })),
    [issueDefinition?.classificationDefinition?.options],
  );

  return (
    <Transition.Collapse
      in={Boolean(issueDefinition)}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {issueDefinition && (
        <>
          {Boolean(classificationOptions?.length) && (
            <div css={styles.base}>
              <Text
                style={
                  classificationsError ? styles.titleIsRequired : undefined
                }
              >
                {'Details:'}
              </Text>
              <CheckboxGroup
                direction={'vertical'}
                isEnabled={instanceCanEdit}
                onChange={handleClassificationChange}
                options={classificationOptions}
                value={selectedClassifications}
                style={styles.classificationSelections}
                error={classificationsError}
              />
            </div>
          )}
          {!issueDefinition.notesDefinition.hidden && (
            <TextAnswer
              id={`issue-note-${answerId}`}
              isEnabled={instanceCanEdit}
              onChange={handleNoteChange}
              floatingText={issueDefinition.notesDefinition.label}
              value={notesValue}
              multiline={true}
              error={notesError}
            />
          )}
        </>
      )}
    </Transition.Collapse>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  classificationSelections: {
    marginLeft: 15,
  },
  titleIsRequired: css({
    color: COLORS.ERROR_RED,
  }),
};

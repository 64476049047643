import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { QueryResult } from '../../types.ts';
import { FormAutomatedActionIssueConfiguration } from '../types.ts';
import { getAutomatedActions } from './automatedActions.ts';
import { getFormTemplateIssues } from './formTemplateIssues.ts';

type GetAutomatedActionIssueConfigurationsArgs = {
  templateId: string;
  showAll: boolean;
};

export const getAutomatedActionIssueConfigurations = async (
  client: IGraphQLClient,
  args: GetAutomatedActionIssueConfigurationsArgs,
): Promise<QueryResult<FormAutomatedActionIssueConfiguration[]>> => {
  const formTemplateIssuesResponse = await getFormTemplateIssues(client, args);
  if (formTemplateIssuesResponse.isSuccess !== true)
    return formTemplateIssuesResponse;

  const automatedActionsResponse = await getAutomatedActions(client, args);
  if (automatedActionsResponse.isSuccess !== true)
    return automatedActionsResponse;

  return {
    isSuccess: true,
    data: formTemplateIssuesResponse.data
      .map((templateIssue) => {
        const matchForAll = automatedActionsResponse.data.find(
          (r) =>
            r.matchIssueLabel === templateIssue.issueLabel &&
            r.matchIssueClassification === null,
        );

        const allClassifications: FormAutomatedActionIssueConfiguration =
          matchForAll
            ? {
                type: 'Issue',
                automatedActionId: matchForAll.id,
                issueLabel: templateIssue.issueLabel,
                issueClassification: null,
                description: matchForAll.description,
                updatedAt: matchForAll.updatedAt,
                updatedByName: matchForAll.updatedBy.name,
                inCurrentDefinition: matchForAll.inCurrentDefinition,
                templateId: args.templateId,
              }
            : {
                type: 'Issue',
                issueLabel: templateIssue.issueLabel,
                issueClassification: null,
                inCurrentDefinition: templateIssue.inCurrentDefinition,
                templateId: args.templateId,
              };

        return [allClassifications].concat(
          templateIssue.classifications.map(
            (c): FormAutomatedActionIssueConfiguration => {
              const match = automatedActionsResponse.data.find(
                (r) =>
                  r.matchIssueLabel === templateIssue.issueLabel &&
                  r.matchIssueClassification === c.classification,
              );

              return match
                ? {
                    type: 'Issue',
                    automatedActionId: match.id,
                    issueLabel: templateIssue.issueLabel,
                    issueClassification: c.classification,
                    description: match.description,
                    updatedAt: match.updatedAt,
                    updatedByName: match.updatedBy.name,
                    inCurrentDefinition: match.inCurrentDefinition,
                    templateId: args.templateId,
                  }
                : {
                    type: 'Issue',
                    issueLabel: templateIssue.issueLabel,
                    issueClassification: c.classification,
                    inCurrentDefinition: c.inCurrentDefinition,
                    templateId: args.templateId,
                  };
            },
          ),
        );
      })
      .flat()
      .filter((a) => a.inCurrentDefinition || a.description),
  };
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import momentTz from 'moment-timezone';
import { ascend, prop, sortWith } from 'ramda';
import { QueryResult } from '../../types.ts';
import {
  FormActionPlanAction,
  FormActionPlanGoal,
  FormInstanceActionPlan,
  FormInstanceActionPlanSection,
  FormInstanceActionPlanType,
  FormInstanceActionPlanWithoutSections,
  FormInstanceActionPlanWithSections,
} from '../types.ts';
import { statusWithOverdue } from '../utils.ts';

const FILTER_INCLUDE_UNCATEGORIZED_GOALS = 'Goals';
const FILTER_INCLUDE_STANDALONE_ACTIONS = 'Coaching Actions';
const FILTER_INCLUDE_ISSUE_ACTIONS = 'Issue Coaching Actions';

const sortActions = sortWith<FormActionPlanAction>([
  ascend(prop('dueBy')),
  ascend(prop('status')),
]);

const sortGoals = sortWith<FormActionPlanGoal>([
  ascend(prop('dueBy')),
  ascend(prop('status')),
]);

const sortSections = sortWith<FormInstanceActionPlanSection>([
  ascend((section) => {
    switch (section.type) {
      case 'Actions':
        return 0;
      case 'IssueActions':
        return 1;
      case 'CategoryGoals':
        return !section.goalCategory.isArchived ? 2 : 3;
      case 'UncategorizedGoals':
        return 4;
      default:
        return 5;
    }
  }),
  ascend((section) =>
    section.type === 'CategoryGoals' ? section.goalCategory.name : '',
  ),
]);

type GetFormInstanceActionPlanArgs = {
  instanceId: string;
  typeFilter?: 'OnlyNew' | 'OnlyExisting';
  includeCategories: string[];
  timezone: string;
  hasSections: boolean;
};

export const getFormInstanceActionPlan = async (
  client: IGraphQLClient,
  args: GetFormInstanceActionPlanArgs,
): Promise<QueryResult<FormInstanceActionPlan>> => {
  try {
    const response = await client.query<{
      forms: {
        formInstanceActionPlanSections: FormInstanceActionPlanSection[];
      };
    }>({
      query: gql`
        query FormInstanceActionPlan(
          $instanceId: ID!
          $includeCoachingActions: Boolean!
          $includeIssueActions: Boolean!
          $goalCategoryFilter: [String]!
          $typeFilter: FormInstanceActionPlanQueryTypeFilter
        ) {
          forms {
            formInstanceActionPlanSections(
              instanceId: $instanceId
              includeCoachingActions: $includeCoachingActions
              includeIssueActions: $includeIssueActions
              goalCategoryFilter: $goalCategoryFilter
              typeFilter: $typeFilter
            ) {
              goalCategory {
                id
                name
                isArchived
              }
              goals {
                id
                actions {
                  id
                  assignedTo {
                    id
                    name
                  }
                  description
                  dueBy
                  status
                }
                assignedTo {
                  id
                  name
                }
                goalCategory {
                  id
                  name
                  isArchived
                }
                description
                dueBy
                status
              }
              actions {
                id
                assignedTo {
                  id
                  name
                }
                formInstanceId
                insightId
                issueId
                description
                dueBy
                status
              }
              type
            }
          }
        }
      `,
      variables: {
        instanceId: args.instanceId,
        includeCoachingActions:
          args.includeCategories.length === 0 ||
          args.includeCategories.includes(FILTER_INCLUDE_STANDALONE_ACTIONS),
        includeIssueActions:
          args.includeCategories.length === 0 ||
          args.includeCategories.includes(FILTER_INCLUDE_ISSUE_ACTIONS),
        goalCategoryFilter: args.includeCategories.filter(
          (filter) =>
            filter !== FILTER_INCLUDE_STANDALONE_ACTIONS &&
            filter !== FILTER_INCLUDE_ISSUE_ACTIONS &&
            filter !== FILTER_INCLUDE_UNCATEGORIZED_GOALS,
        ),
        typeFilter: args.typeFilter,
      },
      fetchPolicy: 'network-only',
    });

    const sections = response.data.forms.formInstanceActionPlanSections;

    return {
      isSuccess: true,
      data: args.hasSections
        ? createResponseWithSections({
            sections,
            includeCategories: args.includeCategories,
            instanceId: args.instanceId,
            timezone: args.timezone,
            typeFilter: args.typeFilter,
          })
        : createResponseWithoutSections({
            sections,
            timezone: args.timezone,
            typeFilter: args.typeFilter,
          }),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form instance action plan instance id ${args.instanceId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

type CreateResponseWithSectionsArgs = {
  sections: FormInstanceActionPlanSection[];
  includeCategories: string[];
  instanceId: string;
  timezone: string;
  typeFilter?: FormInstanceActionPlanType;
};

const createResponseWithSections = ({
  sections,
  includeCategories,
  instanceId,
  timezone,
  typeFilter,
}: CreateResponseWithSectionsArgs): FormInstanceActionPlanWithSections => ({
  hasSections: true,
  includeCategories,
  typeFilter,
  sections: sortSections(addMissingEmptySections(sections))
    .filter((s) => {
      if (includeCategories.length === 0) return true;

      switch (s.type) {
        case 'CategoryGoals':
          return includeCategories.includes(s.goalCategory.name);

        case 'Actions':
          return includeCategories.includes(FILTER_INCLUDE_STANDALONE_ACTIONS);

        case 'UncategorizedGoals':
          return includeCategories.includes(FILTER_INCLUDE_UNCATEGORIZED_GOALS);

        case 'IssueActions':
          return includeCategories.includes(FILTER_INCLUDE_ISSUE_ACTIONS);

        default:
          return true;
      }
    })
    .map((s) => ({
      goalCategory: s.goalCategory && {
        id: s.goalCategory.id,
        name: s.goalCategory.name,
        isArchived: s.goalCategory.isArchived,
      },
      actions: sortActions(s.actions ?? [])
        .filter((a) => (a.issueId ? a.formInstanceId !== instanceId : true))
        .map((a) => ({
          id: a.id,
          assignedTo: a.assignedTo && {
            id: a.assignedTo.id,
            name: a.assignedTo.name,
          },
          formInstanceId: a.formInstanceId,
          insightId: a.insightId,
          issueId: a.issueId,
          description: a.description ?? undefined,
          dueBy: a.dueBy && momentTz(a.dueBy).tz(timezone).toISOString(),
          status: statusWithOverdue(a.status, timezone, a.dueBy),
        })),
      goals: sortGoals(s.goals ?? []).map((g) => ({
        id: g.id,
        actions: sortActions(g.actions ?? []).map((a) => ({
          id: a.id,
          assignedTo: a.assignedTo && {
            id: a.assignedTo.id,
            name: a.assignedTo.name,
          },
          goalCategory: g.goalCategory && {
            id: g.goalCategory.id,
            name: g.goalCategory.name,
            isArchived: g.goalCategory.isArchived,
          },
          goalId: g.id,
          formInstanceId: a.formInstanceId,
          description: a.description ?? undefined,
          dueBy: a.dueBy && momentTz(a.dueBy).tz(timezone).toISOString(),
          status: statusWithOverdue(a.status, timezone, a.dueBy),
        })),
        assignedTo: g.assignedTo && {
          id: g.assignedTo.id,
          name: g.assignedTo.name,
        },
        description: g.description ?? undefined,
        dueBy: g.dueBy && momentTz(g.dueBy).tz(timezone).toISOString(),
        status: statusWithOverdue(g.status, timezone, g.dueBy),
      })),
      type: s.type,
    })),
});

type CreateResponseWithoutSectionsArgs = {
  sections: FormInstanceActionPlanSection[];
  timezone: string;
  typeFilter?: FormInstanceActionPlanType;
};

const createResponseWithoutSections = ({
  sections,
  timezone,
  typeFilter,
}: CreateResponseWithoutSectionsArgs): FormInstanceActionPlanWithoutSections => {
  const actions = sections
    .filter((s) => s.type === 'Actions' || s.type === 'IssueActions')
    .flatMap((s) => s.actions ?? []);

  return {
    hasSections: false,
    actions: sortActions(actions).map((a) => ({
      id: a.id,
      assignedTo: a.assignedTo && {
        id: a.assignedTo.id,
        name: a.assignedTo.name,
      },
      formInstanceId: a.formInstanceId,
      insightId: a.insightId,
      issueId: a.issueId,
      description: a.description ?? undefined,
      dueBy: a.dueBy && momentTz(a.dueBy).tz(timezone).toISOString(),
      status: statusWithOverdue(a.status, timezone, a.dueBy),
    })),
    typeFilter,
  };
};

const addMissingEmptySections = (
  sections: FormInstanceActionPlanSection[],
  typeFilter?: FormInstanceActionPlanType,
): FormInstanceActionPlanSection[] => {
  if (typeFilter === 'OnlyExisting') return sections;

  // These aren't returned from the service if empty.
  // These are needed for users to create their first uncategorized goal or action.
  const hasStandaloneActions = sections.some((s) => s.type === 'Actions');

  // Uncategorized goal creation is only allowed if the tenant has no goal categories configured.
  const hasGoalSection = sections.some(
    (s) => s.type === 'CategoryGoals' || s.type === 'UncategorizedGoals',
  );

  return new Array<FormInstanceActionPlanSection>(
    !hasStandaloneActions && {
      goalCategory: null,
      goals: null,
      actions: [],
      type: 'Actions',
    },
    ...sections,
    !hasGoalSection && {
      goalCategory: null,
      goals: [],
      actions: null,
      type: 'UncategorizedGoals',
    },
  ).filter(Boolean);
};

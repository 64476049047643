import { FormInsight } from '@se/data/forms/types.ts';
import { ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import {
  CheckboxIssueInsightOption,
  IOptionsAnswerLine,
} from '../../types/types.ts';
import {
  IFormInstance,
  ReduxFormInstanceServerLoaded,
} from '../form-instance/types.ts';
import * as insightSlice from './insightSlice.ts';

export function loadedInsightsOnInstanceLoadedEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    map((action) =>
      insightSlice.loadedInsights(
        getInsightsFromInstance(action.payload.instance),
      ),
    ),
  );
}

export const getInsightsFromInstance = (instance: IFormInstance) => {
  if (!instance)
    return {
      insights: new Array<FormInsight>(),
      canView: false,
      loadError: false,
    };

  const { answers, lines } = instance;
  const insightAnswers = Object.entries(answers)
    .filter(([, answer]) => Boolean(answer.insight))
    .map(([key, answer]) => ({ key, answer }));

  if (!insightAnswers.length || !lines)
    return { insights: [], canView: false, loadError: false };

  const insights = insightAnswers
    .map(({ key, answer }): FormInsight => {
      const question = lines[key] as IOptionsAnswerLine;
      const optionsDefinition =
        question.options as CheckboxIssueInsightOption[];
      if (!optionsDefinition) return undefined;

      const answerValue = answer.value?.toString();
      const optionDefinition = optionsDefinition.find(
        (definition) => definition.id?.toString() === answerValue,
      );
      if (!optionDefinition) return undefined;

      const insightDefinition = optionDefinition.insight;
      if (!insightDefinition) return undefined;

      return {
        answerId: answer.id,
        id: answer.insight.id,
        type: insightDefinition.type,
        label: answer.insight.label,
        formSectionName: insightDefinition.formSectionName,
        formQuestionIndex: Object.keys(lines).findIndex(
          (lineKey) => lineKey === key,
        ),
        formQuestionText: insightDefinition.formQuestionText,
        formAnswerSelected: insightDefinition.formAnswerSelected,
        formLineId: insightDefinition.formLineId,
        parentSectionLineId: insightDefinition.parentSectionLineId,
        notes: answer.insight.notes ?? undefined,
        notesDefinition: insightDefinition.notesDefinition,
        classificationDefinition: insightDefinition.classificationDefinition,
        classifications: (answer.insight.classifications ?? []).map(
          (classification) => ({
            index: (
              insightDefinition.classificationDefinition.options ?? []
            ).findIndex((c) => c === classification),
            value: classification,
          }),
        ),
        errors: {},
        isLoading: false,
      };
    })
    .filter(Boolean);

  return {
    insights,
    canView: true,
    loadError: false,
  };
};

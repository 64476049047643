/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  CheckboxGroup,
  CheckboxGroupEvent,
  CheckboxValue,
  RadioGroup,
  RadioGroupEvent,
  RadioOption,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useCallback } from 'react';
import { OptionAnswer } from '../../../types/types.ts';

export type OptionsAnswerProps = {
  direction?: 'horizontal' | 'vertical';
  error?: string;
  group?: string;
  isEnabled?: boolean;
  multiSelect?: boolean;
  onChange: (to: string, toDisplayValue: string) => void;
  options?: OptionAnswer[];
  question?: string;
  value?: string;
};

export const OptionsAnswer: React.FC<OptionsAnswerProps> = ({
  direction = 'vertical',
  error,
  isEnabled = true,
  multiSelect = false,
  onChange,
  options = [],
  question,
  value,
}) => {
  const radioOptions = options as RadioOption[];

  const handleRadioChange = useCallback(
    (e: RadioGroupEvent) => {
      const toDisplayValue = radioOptions.find(
        (option) => e.id === option.id,
      )?.label;
      const to = e.id;
      onChange(to, toDisplayValue);
    },
    [onChange, radioOptions],
  );

  const valueToCheckbox = useCallback(
    (answerValue?: string): CheckboxValue[] => {
      const selectedOptions = answerValue?.startsWith('[')
        ? answerValue.substring(1, answerValue.length - 1)
        : answerValue;
      return (
        selectedOptions
          ?.split(',')
          .map((option) => ({ id: option, checked: true })) || []
      );
    },
    [],
  );

  const checkboxToAnswer = useCallback(
    (e: CheckboxGroupEvent): string => {
      const to = { id: e.id, checked: e.to };
      const mergedWithExisting = value ? [...valueToCheckbox(value), to] : [to];

      return mergedWithExisting
        .map((option) =>
          option.id === e.id ? { ...option, checked: e.to } : option,
        )
        .filter((option) => option.checked)
        .reduce((acc, option, index, all) => {
          const isFirst = index === 0;
          const isLast = index === all.length - 1;

          if (isFirst && isLast) return `[${option.id}]`;
          if (isFirst) return `[${option.id}`;

          return isLast ? `${acc},${option.id}]` : `${acc},${option.id}`;
        }, '');
    },
    [value, valueToCheckbox],
  );

  const checkboxDisplayValue = useCallback(
    (selectedAnswers: string): string => {
      const checkedIds = valueToCheckbox(selectedAnswers)
        .filter((cb) => cb.checked)
        .map((cb) => cb.id);

      return radioOptions
        .filter((option) => checkedIds.includes(option.id))
        .map((option) => option.label)
        .join(`; `);
    },
    [radioOptions, valueToCheckbox],
  );

  const handleCheckboxChange = useCallback(
    (e: CheckboxGroupEvent) => {
      const to = checkboxToAnswer(e);
      onChange(to, checkboxDisplayValue(to));
    },
    [checkboxDisplayValue, checkboxToAnswer, onChange],
  );

  const isHorizontal = direction === 'horizontal';

  return (
    <div
      css={[
        styles.base,
        isHorizontal ? styles.horizontal : styles.vertical,
        error && styles.error,
      ]}
    >
      {question && (
        <Markdown
          style={error ? styles.questionError : styles.question}
          text={question}
        />
      )}
      <div
        css={isHorizontal ? styles.optionsHorizontal : styles.optionsVertical}
      >
        {multiSelect ? (
          <CheckboxGroup
            direction={direction}
            value={valueToCheckbox(value)}
            options={radioOptions}
            onChange={handleCheckboxChange}
            isEnabled={isEnabled}
            error={error}
          />
        ) : (
          <RadioGroup
            direction={direction}
            value={value}
            options={radioOptions}
            onChange={handleRadioChange}
            isEnabled={isEnabled}
            error={error}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
    gap: '0 10px',
  }),
  horizontal: css({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  vertical: css({
    flexDirection: 'column',
  }),
  error: css({
    padding: 3,
  }),
  optionsHorizontal: css({
    flex: '0 1 auto',
  }),
  optionsVertical: css({
    flex: '0 1 auto',
    paddingLeft: 10,
  }),
  question: css({
    flex: '0 5000 auto',
  }),
  questionError: css({
    flex: '0 5000 auto',
    color: COLORS.ERROR_RED,
  }),
};

import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, ofType } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  debounceTime,
  filter,
  from,
  map,
} from 'rxjs';
import { FormAnswer } from '../../types/types.ts';
import { hasInsights } from '../../util/util.instance.ts';
import { automatedActionSlice } from '../automatedAction/index.ts';
import { ReduxFormInstanceAnswerChange } from '../form-instance/types.ts';
import { GlobalFormsState } from '../store.ts';
import * as insightSlice from './insightSlice.ts';

export function isLoadingOnExistingInsightForAnswerIdChangedEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) =>
      Boolean(
        action.payload.change.type === 'UPDATE' &&
          action.payload.change.fromAnswer &&
          action.payload.change.postChangeQueryIssueInsightId,
      ),
    ),
    map((action) =>
      insightSlice.existingInsightForAnswerIdLoading({
        answerId: action.payload.change.fromAnswer.id,
      }),
    ),
  );
}

export function addInsightForOptionsAnswerWithInsightEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(insightSlice.answerChangedWithNewInsightId.match),
    filter((action) =>
      hasInsights(
        state$.value.formInstance.instances[action.payload.instanceId],
      ),
    ),
    map((action) => {
      const instanceId = action.payload.instanceId;
      const instanceLines =
        state$.value.formInstance.instances[instanceId].lines;
      const toAnswer = action.payload.toAnswer;

      const insightDefinition = getInsightDefinition(
        instanceId,
        toAnswer,
        state$.value,
      );

      const answerId = toAnswer.id;

      const existingAnswerInsight = state$.value.formInsight.insights.find(
        (insight) => insight.answerId === answerId,
      );

      const removeExistingInsight =
        existingAnswerInsight &&
        insightSlice.removeInsightByAnswerId({
          answerId: existingAnswerInsight.answerId,
        });

      const addNewInsight = insightSlice.addInsight({
        answerId,
        classificationDefinition: insightDefinition.classificationDefinition,
        classifications: existingAnswerInsight?.classifications?.length
          ? insightDefinition.classificationDefinition?.options
              ?.map((classificationDefinition, index) => {
                const copyClassificationToNew =
                  existingAnswerInsight.classifications.some(
                    (existingClassification) =>
                      existingClassification.value === classificationDefinition,
                  );

                return copyClassificationToNew
                  ? { index, value: classificationDefinition }
                  : undefined;
              })
              .filter(Boolean)
          : [],
        formAnswerSelected: insightDefinition.formAnswerSelected,
        formLineId: insightDefinition.formLineId,
        formQuestionIndex: Object.keys(instanceLines).findIndex(
          (lineKey) => lineKey === toAnswer.lineId,
        ),
        formQuestionText: insightDefinition.formQuestionText,
        formSectionName: insightDefinition.formSectionName,
        id: action.payload.insightId,
        label: insightDefinition.label,
        notes: existingAnswerInsight?.notes,
        notesDefinition: insightDefinition.notesDefinition,
        parentSectionLineId: insightDefinition.parentSectionLineId,
        type: insightDefinition.type,
      });

      return from([removeExistingInsight, addNewInsight].filter(Boolean));
    }),
    concatAll(),
  );
}

export function removeInsightForOptionsAnswerWithoutInsightEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (change.type === 'DELETE') return false;

      const answer = change.toAnswer;

      const exists = state$.value.formInsight.insights.some(
        (insight) => insight.answerId === answer.id,
      );
      return exists && !getInsightDefinition(instanceId, answer, state$.value);
    }),
    map((action) => {
      const answerId = action.payload.change.toAnswer?.id;

      const existingInsightForAnswerId = state$.value.formInsight.insights.find(
        (insight) => insight.answerId === answerId,
      );

      return from([
        insightSlice.removeInsightByAnswerId({
          answerId: existingInsightForAnswerId.answerId,
        }),
        automatedActionSlice.setAnswerAutomatedActions({
          answerId: existingInsightForAnswerId.answerId,
          automatedActions: [],
        }),
      ]);
    }),
    concatAll(),
  );
}

export function removeInsightOnOptionsAnswerHiddenEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (change.type !== 'DELETE') return false;

      const instance = state$.value.formInstance.instances[instanceId];
      if (!instance) return false;

      return state$.value.formInsight.insights.some(
        (insight) => insight.answerId === change.fromAnswer.id,
      );
    }),
    map((action) =>
      insightSlice.removeInsightByAnswerId({
        answerId: action.payload.change.fromAnswer.id,
      }),
    ),
  );
}

export function saveCopiedInsightDetailsOnOptionsAnswerSavedEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    debounceTime(10),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (change.type === 'DELETE') return false;

      return Boolean(
        getInsightDefinition(instanceId, change.toAnswer, state$.value),
      );
    }),
    map((action) => {
      const { instanceId, change } = action.payload;
      const toAnswer = change.toAnswer;

      const existingAnswerInsight = state$.value.formInsight.insights.find(
        (insight) => insight.answerId === toAnswer.id,
      );

      if (!existingAnswerInsight) return EMPTY;

      const setClassifications = existingAnswerInsight.classifications?.length
        ? insightSlice.saveClassifications({
            answerId: toAnswer.id,
            instanceId,
            concurrencyId: change.toAnswer.lineId,
          })
        : undefined;

      const setNotes = existingAnswerInsight.notes
        ? insightSlice.setNotes({
            instanceId,
            answerId: toAnswer.id,
            notes: existingAnswerInsight.notes,
            concurrencyId: change.toAnswer.lineId,
          })
        : undefined;

      return from([setClassifications, setNotes].filter(Boolean));
    }),
    concatAll(),
  );
}

const getInsightDefinition = (
  instanceId: string,
  answer: FormAnswer,
  state: GlobalFormsState,
) => {
  if (answer.type !== 'optionsAnswer') return undefined;
  if (answer.subType !== 'RADIO') return undefined;

  const answerValue = answer.value;
  if (!answerValue) return undefined;

  const instance = state.formInstance.instances[instanceId];
  if (!instance) return undefined;

  const formLineId = answer.lineId;
  const formLine = instance.lines[formLineId];
  if (!formLine) return undefined;
  if (formLine.type !== 'optionsAnswer') return undefined;

  const answerOptions = formLine.options;
  if (!answerOptions?.length) return undefined;

  const answerOption = answerOptions.find(
    (option) => option.id === answerValue,
  );
  return answerOption?.insight;
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { uniqWith } from 'ramda';
import { QueryResult } from '../../types.ts';
import { FormAutomatedActionInsightConfiguration } from '../types.ts';
import { getAutomatedActions } from './automatedActions.ts';
import { getFormTemplateInsights } from './formTemplateInsights.ts';

type GetAutomatedActionInsightConfigurationsArgs = {
  templateId: string;
  showAll: boolean;
};

export const getAutomatedActionInsightConfigurations = async (
  client: IGraphQLClient,
  args: GetAutomatedActionInsightConfigurationsArgs,
): Promise<QueryResult<FormAutomatedActionInsightConfiguration[]>> => {
  const formTemplateInsightsResponse = await getFormTemplateInsights(
    client,
    args,
  );
  if (formTemplateInsightsResponse.isSuccess !== true)
    return formTemplateInsightsResponse;

  const automatedActionsResponse = await getAutomatedActions(client, args);
  if (automatedActionsResponse.isSuccess !== true)
    return automatedActionsResponse;

  const insightConfigurations = formTemplateInsightsResponse.data
    .map((templateInsight) => {
      const matchForAll = automatedActionsResponse.data.find(
        (r) =>
          r.matchInsightLabel === templateInsight.insightLabel &&
          r.matchInsightClassification === null,
      );

      const allClassifications: FormAutomatedActionInsightConfiguration =
        matchForAll
          ? {
              type: 'Insight',
              insightType: templateInsight.type,
              automatedActionId: matchForAll.id,
              insightLabel: templateInsight.insightLabel,
              insightClassification: null,
              description: matchForAll.description,
              updatedAt: matchForAll.updatedAt,
              updatedByName: matchForAll.updatedBy.name,
              inCurrentDefinition: matchForAll.inCurrentDefinition,
              templateId: args.templateId,
            }
          : {
              type: 'Insight',
              insightType: templateInsight.type,
              insightLabel: templateInsight.insightLabel,
              insightClassification: null,
              inCurrentDefinition: templateInsight.inCurrentDefinition,
              templateId: args.templateId,
            };

      return [allClassifications].concat(
        templateInsight.classifications.map(
          (c): FormAutomatedActionInsightConfiguration => {
            const match = automatedActionsResponse.data.find(
              (r) =>
                r.matchInsightLabel === templateInsight.insightLabel &&
                r.matchInsightClassification === c.classification,
            );

            return match
              ? {
                  type: 'Insight',
                  insightType: templateInsight.type,
                  automatedActionId: match.id,
                  insightLabel: templateInsight.insightLabel,
                  insightClassification: c.classification,
                  description: match.description,
                  updatedAt: match.updatedAt,
                  updatedByName: match.updatedBy.name,
                  inCurrentDefinition: match.inCurrentDefinition,
                  templateId: args.templateId,
                }
              : {
                  type: 'Insight',
                  insightType: templateInsight.type,
                  insightLabel: templateInsight.insightLabel,
                  insightClassification: c.classification,
                  inCurrentDefinition: c.inCurrentDefinition,
                  templateId: args.templateId,
                };
          },
        ),
      );
    })
    .flat()
    .filter(
      (a) =>
        a.insightType === 'LearningArea' ||
        (a.insightType === 'Neutral' && a.insightClassification !== null),
    )
    .filter((a) => a.inCurrentDefinition || a.description);

  const uniqueInsightConfigurations = uniqWith(
    (a, b) =>
      a.insightLabel === b.insightLabel &&
      a.insightClassification === b.insightClassification,
    insightConfigurations,
  );

  return {
    isSuccess: true,
    data: uniqueInsightConfigurations,
  };
};

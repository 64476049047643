/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import { FormAnswerAutomatedAction } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { ascend, prop, sortWith } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { formActionSlice } from '../../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { SelectAutomatedActionPopup } from '../../SelectAutomatedActionPopup/SelectAutomatedActionPopup.tsx';

const sortAutomatedActions = sortWith<FormAnswerAutomatedAction>([
  ascend(prop('formQuestionIndex')),
  ascend(prop('issueInsightClassificationIndex')),
  ascend(prop('label')),
]);

export type AddActionProps = {
  error?: boolean;
  goalId?: string;
  instanceId: string;
  issueId?: string;
  message: string;
  showAutomatedActions?: boolean;
  style?: Interpolation<Theme>;
};

export const AddAction: React.FC<AddActionProps> = ({
  error,
  goalId,
  instanceId,
  issueId,
  message,
  showAutomatedActions,
  style,
}) => {
  const dispatch = useFormsDispatch();
  const [dropdownShowing, setDropdownShowing] = useState(false);

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const answerAutomatedActions = useFormsSelector(
    (state) => state.automatedAction.answerAutomatedActions,
  );

  const availableAutomatedActions = useMemo(() => {
    if (!showAutomatedActions) return [];

    const unsorted = Object.values(answerAutomatedActions)
      .filter((aa) => !aa.isLoading)
      .map((aa) => aa.automatedActions)
      .flat()
      .filter((aa) => !aa.createdActionId);

    return sortAutomatedActions(unsorted);
  }, [answerAutomatedActions, showAutomatedActions]);

  const isLoading = useFormsSelector((state) => {
    if (!showAutomatedActions) return false;
    return state.automatedAction.isLoading;
  });

  const issues = useFormsSelector((state) => state.formIssue.issues);
  const insights = useFormsSelector((state) => state.formInsight.insights);

  const instanceIssues = useMemo(
    () =>
      (issues ?? []).map((i) => ({
        id: i.id,
        label: i.label,
        formSectionName: i.formSectionName,
        issueCoachingRequired: i.issueCoachingRequired,
      })),
    [issues],
  );

  const assignedTo = useMemo(
    () =>
      instanceSubject?.kind === 'Person'
        ? { id: instanceSubject.id, name: instanceSubject.name }
        : undefined,
    [instanceSubject],
  );

  const handleNewActionDraft = useCallback(
    (automatedAction?: FormAnswerAutomatedAction) => {
      const overrideIssueId = automatedAction?.issueId ?? issueId;

      dispatch(
        formActionSlice.newDraft({
          actionId: uuid.generate(),
          assignedTo,
          goalId,
          issue: overrideIssueId
            ? instanceIssues?.find((i) => i.id === overrideIssueId)
            : undefined,
          instanceIssues,
          instanceInsights: (insights ?? []).map((i) => ({
            id: i.id,
            label: i.label,
            formSectionName: i.formSectionName,
            classifications: (i.classifications ?? []).map((c) => c.value),
            type: i.type,
          })),
          formInstanceSubject: instanceSubject
            ? {
                id: instanceSubject.id,
                label: instanceSubject.name,
                type: instanceSubject.kind === 'Person' ? 'PERSON' : 'TEAM',
              }
            : undefined,
          openedFromFormInstanceId: instanceId,
          description: automatedAction?.description,
          answerAutomatedActionId: automatedAction?.id,
        }),
      );
    },
    [
      assignedTo,
      dispatch,
      goalId,
      insights,
      instanceId,
      instanceIssues,
      instanceSubject,
      issueId,
    ],
  );

  const hideDropdown = useCallback(() => setDropdownShowing(false), []);
  const showDropdown = useCallback(() => setDropdownShowing(true), []);

  const computedStyles = {
    button: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'flex-start',
      color: error ? COLORS.ERROR_RED : COLORS.BLUE,
    },
  };

  return (
    <div css={[styles.base, style]}>
      <TextButton
        textButtonStyle={computedStyles.button}
        onClick={
          !isLoading && availableAutomatedActions?.length
            ? showDropdown
            : handleNewActionDraft
        }
        icon={Icons.plus}
        iconStyle={error ? { fill: COLORS.ERROR_RED } : undefined}
        cursor={'inherit'}
        textStyle={styles.buttonText}
        highlightOnHover={true}
        color={error ? COLORS.ERROR_RED : COLORS.BLUE}
        dataTest={'forms-actionPlan-addActionButton'}
      >
        <div css={styles.buttonContent}>
          {message}
          {isLoading && <Spinner />}
          {!isLoading && availableAutomatedActions?.length > 0 && (
            <div css={styles.automatedActions}>
              <Icons.bulbAndSparkles fill={'white'} />
              <Text
                uppercase={true}
                color={'white'}
                size={15}
                cursor={'pointer'}
              >{`${availableAutomatedActions.length} recommended ${str.plural('action', availableAutomatedActions.length)}`}</Text>
            </div>
          )}
        </div>
      </TextButton>
      {dropdownShowing && (
        <SelectAutomatedActionPopup
          onHide={hideDropdown}
          automatedActions={availableAutomatedActions}
          onCreateAction={handleNewActionDraft}
          showIssueLabel={true}
        />
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  }),
  buttonContent: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  }),
  buttonText: css(CommonStyles.MaterialCubicTransitions),
  automatedActions: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    padding: '3px 15px 3px 8px',
    backgroundColor: '#ff671d',
    border: `solid 1px ${color.format(-0.1)}`,
    borderRadius: 5,
  }),
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map, mergeMap } from 'rxjs';
import { FormAnswer, IFormSelectionListItem } from '../../../types/types.ts';
import {
  hasInsights,
  hasIssueCoaching,
  hasIssues,
  validateRequiredFields,
} from '../../../util/util.instance.ts';
import { insightValidationErrorActions } from '../../insight/util.ts';
import {
  complianceIssueValidationErrorActions,
  issueCoachingPlanValidationErrorActions,
} from '../../issue/util.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { formInstanceAnswerRequiredError } from '../answer/actions.ts';
import { createAnswer, updateAnswer } from '../answer/mutation.ts';
import {
  FormAnswerByKey,
  IFormInstance,
  ReduxFormInstanceUserSignoff,
} from '../types.ts';
import {
  clearErrorsForAnswerKeys,
  serverSignoff,
  validationFailed,
} from './actions.ts';
import { queryInstanceLoad } from './query.ts';

export function addSecondSignoffEpic(
  action$: Observable<ReduxFormInstanceUserSignoff>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/USER_SIGNOFF'),
    filter((action) => {
      const instance =
        state$.value.formInstance.instances[action.payload.instanceId];
      if (!instance) return false;

      return (
        instance.signOffs.length === 1 &&
        ['Appealed', 'Pending'].includes(instance.status.status)
      );
    }),
    map(({ payload }) =>
      serverSignoff(
        payload.instanceId,
        payload.personId,
        payload.signature,
        payload.appealResponse,
      ),
    ),
  );
}

export function showErrorBannerOnInstanceSignoffFieldsErrorEpic(
  action$: Observable<ReduxFormInstanceUserSignoff>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/USER_SIGNOFF'),
    filter((action) => {
      const instance =
        state$.value.formInstance.instances[action.payload.instanceId];
      if (!instance) return false;

      if (instance.signOffs.length !== 0) return false;

      return Object.values(instance.questionErrors).some(Boolean);
    }),
    map((action) => validationFailed(action.payload.instanceId)),
  );
}

export function validateForFirstSignoffEpic(
  action$: Observable<ReduxFormInstanceUserSignoff>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/USER_SIGNOFF'),
    filter((action) => {
      const instance =
        state$.value.formInstance.instances[action.payload.instanceId];
      if (!instance) return false;

      return instance.signOffs.length === 0;
    }),
    mergeMap(async (action) => {
      const { instanceId, personId, signature, appealResponse } =
        action.payload;
      const instance = state$.value.formInstance.instances[instanceId];
      const formScore = state$.value.formScore;
      const tenantTimezone =
        state$.value.tenantState.tenant.configuration.timezone;

      const savedMismatchedAnswerKeys = await saveClientServerMismatchedAnswers(
        instance,
        client,
      );

      const clearErrorsAction = savedMismatchedAnswerKeys.size
        ? clearErrorsForAnswerKeys(
            instanceId,
            [...savedMismatchedAnswerKeys.keys()],
            'MISMATCH_RECONCILIATION',
          )
        : undefined;

      const requiredQuestionKeys = validateRequiredFields(
        instance,
        formScore,
        tenantTimezone,
      );

      const insightErrors = hasInsights(instance)
        ? insightValidationErrorActions(state$.value)
        : undefined;

      const complianceIssueErrors = hasIssues(instance)
        ? complianceIssueValidationErrorActions(state$.value)
        : undefined;

      const coachingPlanErrors =
        hasIssueCoaching(instance, false) &&
        issueCoachingPlanValidationErrorActions(instanceId, state$.value);

      const hasErrors = Boolean(
        requiredQuestionKeys.length ||
          complianceIssueErrors?.length ||
          coachingPlanErrors?.length ||
          insightErrors?.length,
      );

      if (hasErrors)
        return from(
          [
            formInstanceAnswerRequiredError(instanceId, requiredQuestionKeys),
            clearErrorsAction,
            insightErrors,
            complianceIssueErrors,
            coachingPlanErrors,
            validationFailed(instanceId),
          ]
            .flat()
            .filter(Boolean),
        );

      return from(
        [
          clearErrorsAction,
          serverSignoff(instanceId, personId, signature, appealResponse),
        ].filter(Boolean),
      );
    }),
    concatAll(),
  );
}

type FormInstanceAnswerResponse = {
  id: string;
  key: string;
  value: string | IFormSelectionListItem;
  displayValue: string;
};

type FormInstanceResponse = {
  forms: {
    formInstance: {
      id: string;
      answers: {
        nodes: FormInstanceAnswerResponse[];
      };
    };
  };
};

async function getServerInstanceAnswers(
  instanceId: string,
  client: IGraphQLClient,
) {
  try {
    const response = await client.query<FormInstanceResponse>({
      query: queryInstanceLoad({
        includeAttendance: false,
      }),
      variables: { instanceId },
      fetchPolicy: 'network-only',
    });

    return response.data.forms.formInstance?.answers.nodes;
  } catch (err) {
    log.error(`Problem querying form with id ${instanceId}`, err);
  }
}

function answersAreDifferent(
  serverAnswer: FormInstanceAnswerResponse,
  clientAnswer: FormAnswer,
) {
  return (
    clientAnswer?.value !== undefined &&
    clientAnswer.value !== serverAnswer.value
  );
}

function getClientServerDifferentAnswers(
  serverAnswers: FormInstanceAnswerResponse[],
  clientAnswers: FormAnswerByKey,
) {
  return Object.entries(clientAnswers).filter(
    ([clientAnswerKey, clientAnswerValue]) => {
      if (clientAnswerValue === undefined) return false;
      if (clientAnswerKey === 'reportingDate') return false;
      if (!clientAnswerValue.id) return false;

      const matchingServerAnswer = serverAnswers.find(
        (serverAnswer) => serverAnswer.key === clientAnswerKey,
      );
      if (!matchingServerAnswer) return false;

      return answersAreDifferent(matchingServerAnswer, clientAnswerValue);
    },
  );
}

async function saveClientServerMismatchedAnswers(
  instance: IFormInstance,
  client: IGraphQLClient,
) {
  const result = new Map<string, 'DIFFERENT' | 'MISSING'>();

  if (!instance) return result;
  if (instance.status.status !== 'InProgress') return result;
  if (!instance.permissions.edit) return result;

  const serverInstanceAnswers = await getServerInstanceAnswers(
    instance.id,
    client,
  );
  if (!serverInstanceAnswers?.length) return result;

  const differentAnswers = getClientServerDifferentAnswers(
    serverInstanceAnswers,
    instance.answers,
  );

  await Promise.all(
    differentAnswers.map(([, answer]) =>
      updateAnswer(client, instance.id, answer),
    ),
  );

  const missingAnswers = Object.entries(instance.answers).filter(
    ([answerKey, answerValue]) => {
      if (answerValue === undefined) return false;
      if (answerKey === 'reportingDate') return false;
      if (!answerValue.id) return false;
      if (!answerValue.value) return false;

      return serverInstanceAnswers.every(
        (serverAnswer) => serverAnswer.key !== answerKey,
      );
    },
  );

  await Promise.all(
    missingAnswers.map(([, answer]) =>
      createAnswer(client, instance.id, answer),
    ),
  );

  differentAnswers.forEach(([key]) => result.set(key, 'DIFFERENT'));
  missingAnswers.forEach(([key]) => result.set(key, 'MISSING'));

  return result;
}

/** @jsxImportSource @emotion/react */
import { FormInstanceActionPlanType } from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantFeature } from '@seeeverything/ui.primitives/src/hooks/useTenantFeature.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { GoalCategory } from './GoalCategory.tsx';
import { IssueActions } from './IssueActions.tsx';
import { StandaloneActions } from './StandaloneActions.tsx';

export type ActionPlanWithSectionsProps = {
  includeCategories: string[];
  instanceId: string;
  isPdfExport: boolean;
  lineId: string;
  typeFilter?: FormInstanceActionPlanType;
};

export const ActionPlanWithSections: React.FC<ActionPlanWithSectionsProps> = ({
  includeCategories,
  instanceId,
  isPdfExport,
  lineId,
  typeFilter,
}) => {
  const standaloneActionsEnabled = useTenantFeature(
    (f) => f.forms.formsStandaloneActions,
  );

  const actionPlanSections = useFormsSelector((state) => {
    const plan = state.formActionPlan.actionPlan[lineId].plan;
    return plan.hasSections ? plan.sections : [];
  });

  const canCreateInInstance = useFormsSelector((state) => {
    if (isPdfExport) return false;

    const instance = state.formInstance.instances[instanceId];
    if (!instance) return false;
    if (!instance.permissions.edit) return false;

    if (instance.status.status === 'InProgress') return true;
    if (instance.status.status === 'Created') return true;

    return false;
  });

  const instanceLines = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.lines,
  );

  const instanceHasInsights = useMemo(() => {
    if (!instanceLines) return false;

    return Object.values(instanceLines).some(
      (line) =>
        line.type === 'optionsAnswer' &&
        (line.options ?? []).some((o) => Boolean(o.insight)),
    );
  }, [instanceLines]);

  const visibleSections = useMemo(() => {
    if (canCreateInInstance) return actionPlanSections;

    return actionPlanSections.filter(({ type, actions, goals }) => {
      if (type === 'Actions' && !actions.length) return false;
      if (type === 'IssueActions' && !actions.length) return false;
      if (type === 'CategoryGoals' && !goals.length) return false;
      if (type === 'UncategorizedGoals' && !goals.length) return false;
      return true;
    });
  }, [actionPlanSections, canCreateInInstance]);

  return (
    <>
      {!visibleSections.length && (
        <Text color={color.format(-0.5)} italic={true}>
          {'No existing goals.'}
        </Text>
      )}
      {visibleSections.map((section) => {
        if (section.type === 'Actions' && standaloneActionsEnabled)
          return (
            <StandaloneActions
              instanceId={instanceId}
              isDefaultExpanded={
                isPdfExport ||
                includeCategories.length === 1 ||
                instanceHasInsights
              }
              actions={section.actions}
              canCreateActions={canCreateInInstance}
              typeFilter={typeFilter}
              key={section.type}
              categoryGrouping={true}
            />
          );

        if (section.type === 'IssueActions')
          return (
            <IssueActions
              instanceId={instanceId}
              isDefaultExpanded={isPdfExport || includeCategories.length === 1}
              key={section.type}
              actions={section.actions}
            />
          );

        if (section.type === 'CategoryGoals' && section.goalCategory)
          return (
            <GoalCategory
              canCreateGoals={!isPdfExport}
              goalCategoryArchived={section.goalCategory.isArchived}
              goals={section.goals}
              goalCategoryId={section.goalCategory.id}
              instanceId={instanceId}
              isDefaultExpanded={isPdfExport || includeCategories.length === 1}
              isPdfExport={isPdfExport}
              key={section.goalCategory.name}
              name={section.goalCategory.name}
            />
          );

        if (section.type === 'UncategorizedGoals') {
          const hasGoalCategories = actionPlanSections.some(
            (s) => s.type === 'CategoryGoals',
          );

          return (
            <GoalCategory
              canCreateGoals={!isPdfExport}
              goalCategoryArchived={hasGoalCategories}
              goals={section.goals}
              instanceId={instanceId}
              isDefaultExpanded={isPdfExport || includeCategories.length === 1}
              isPdfExport={isPdfExport}
              key={section.type}
              name={'Goals'}
            />
          );
        }
      })}
    </>
  );
};

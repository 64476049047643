/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormIssue } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useFormsSelector } from '../../../redux/store.ts';
import { Bullet } from '../../Bullet/Bullet.tsx';
import { IssueContainer } from '../../Issue/Issue.tsx';
import { SectionTitle } from '../../SectionTitle/SectionTitle.tsx';
import { SpeechBlockContainer } from '../../SpeechBlock/SpeechBlockContainer.tsx';
import { IssueCoaching } from './IssueCoaching.tsx';

export type IssueCoachingGroupProps = {
  instanceId: string;
  isPdfExport: boolean;
  issues: FormIssue[];
  requirement: 'MANDATORY' | 'RECOMMENDED';
};

export const IssueCoachingGroup: React.FC<IssueCoachingGroupProps> = ({
  requirement,
  instanceId,
  isPdfExport,
  issues,
}) => {
  const labels = useFormsSelector(
    (state) => state.tenantState.tenant.locale.forms.closeTheLoop,
  );

  if (!issues.length) return null;

  return (
    <div css={styles.base}>
      <div css={styles.sectionTitleOuter}>
        <SectionTitle
          text={
            requirement === 'MANDATORY'
              ? 'Coaching Required'
              : 'Coaching Recommended'
          }
        />
      </div>
      <div css={styles.speechBlockOuter}>
        <Bullet
          bullet={isPdfExport ? 'none' : 'person'}
          style={styles.speechBlockBullet}
        />
        <SpeechBlockContainer
          id={
            requirement === 'MANDATORY'
              ? 'issueReviewRequiresCoaching'
              : 'issueReviewOptionalCoaching'
          }
          instanceId={instanceId}
          isPdfExport={isPdfExport}
          markdown={
            requirement === 'MANDATORY'
              ? labels.issueCoachingPlanCoachingRequiredGuidanceScript
              : labels.issueCoachingPlanCoachingRecommendedGuidanceScript
          }
        />
      </div>
      {issues.map((issue) => (
        <div
          key={`issueCoachingPlanBase-${issue.id}`}
          css={
            Object.values(issue.errors.coaching).some(Boolean)
              ? styles.issueBaseError
              : styles.issueBase
          }
        >
          <div key={`issueCoachingPlanOuter-${issue.id}`} css={styles.issues}>
            <IssueContainer
              key={`issueCoachingPlanContainer-${issue.id}`}
              id={issue.id}
              enableActions={false}
              showReviewLink={true}
              instanceId={instanceId}
              showCreatedActions={false}
            />
            <IssueCoaching
              key={`issueCoachingPlanCause-${issue.id}`}
              issue={issue}
              lineId={'issueCoaching'}
              instanceId={instanceId}
              requirement={requirement}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 30,
  }),
  issueBase: css({
    ...CommonStyles.BoxShadowGroup,
    padding: 10,
  }),
  issueBaseError: css({
    boxShadow: `0 1px 4px 1px ${COLORS.ERROR_RED}`,
    transition: 'all 0.3s',
    borderRadius: 4,
    ':hover': {
      boxShadow: `0 1px 6px 1px ${COLORS.RED}`,
    },
    padding: 10,
  }),
  issues: css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 4,
    gap: 20,
  }),
  sectionTitleOuter: css({
    position: 'relative',
  }),
  speechBlockOuter: css({
    position: 'relative',
  }),
  speechBlockBullet: css({
    position: 'absolute',
    inset: '9px auto auto -37px',
  }),
};

import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { CommonDefinition } from '../parse/types/common.types.ts';
import {
  DropdownAnswerDefinition,
  InputDefinition,
} from '../parse/types/input.types.ts';
import { OptionsDefinition } from '../parse/types/options.types.ts';
import {
  ScoreSectionDefinition,
  SectionQuestionDefinition,
} from '../parse/types/section.types.ts';
import { SliderDefinition } from '../parse/types/slider.types.ts';
import { FormTemplateItemDefinition } from '../parse/types/template.types.ts';
import { ExportableQuestion } from '../redux/formBulkUploadDownloadTemplate/formBulkUploadDownloadTemplateSlice.ts';
import { getProp } from '../util/util.data.parse.ts';
import {
  ANSWER_TYPE_LABELS,
  DROPDOWN_RESERVED_IDS,
  EXPORTABLE_QUESTIONS,
  KEY_ID_SORTS,
  MANDATORY_FIELDS,
  QuestionTypes,
} from './constants.ts';
import { getIssueInsightQuestions } from './util.issueInsights.ts';
import {
  isOptionsAnswerDefinition,
  isRichSelectOption,
  isScoringItem,
  isScoringSectionDefinition,
  isSection,
  isSelectAnswerDefinition,
} from './util.typeGuards.ts';

/**
 * Checks if template has enabled scoring outcome.
 */
const templateHasScoringOutcome = (template: FormTemplateItemDefinition[]) => {
  const isScoringEnabled = template.some(
    (question) => isScoringItem(question) && question.scoring.isEnabled,
  );
  if (!isScoringEnabled) return false;

  return template.some((item) => {
    if (!isSection(item)) return false;

    const scoreSection = item.section.questions.find(
      (sectionQuestion) =>
        isScoringSectionDefinition(sectionQuestion) &&
        Boolean(sectionQuestion.score),
    ) as ScoreSectionDefinition;
    if (!scoreSection) return false;

    const scoreOverrules = scoreSection.score.overrules;
    return Boolean(scoreOverrules?.result && scoreOverrules?.undetermined);
  });
};

/**
 * Parses a form template definition and returns all question keys that can be exported.
 */
export const exportableQuestionsFromTemplate = (
  template: FormTemplateItemDefinition[],
  showHiddenQuestions: boolean,
  existingKeys?: ExportableQuestion[],
): ExportableQuestion[] => {
  const exportableQuestions = template
    .filter(isSection)
    .reduce<ExportableQuestion[]>(
      (acc, item, index) => {
        const section = item.section;

        if (!section.questions) return acc;
        if (!showHiddenQuestions && !isDefaultVisible(section)) return acc;

        const sectionId = `section-${section.id ?? section.title}`;

        const questions = section.questions
          .map((question) =>
            sectionInputQuestions(
              question,
              showHiddenQuestions,
              sectionId,
              existingKeys,
            ),
          )
          .flat()
          .filter((question) => question.id || question.label);

        const selectedQuestions = questions.filter(
          (question) =>
            question.isSelected && !MANDATORY_FIELDS.includes(question.id),
        );

        const selectableQuestions = questions.filter(
          (question) => !MANDATORY_FIELDS.includes(question.id),
        );

        const headerSelected =
          selectedQuestions.length === selectableQuestions.length
            ? true
            : selectedQuestions.length > 0
              ? ('indeterminate' as const)
              : undefined;

        const sectionHeader = {
          id: sectionId,
          label: `Section ${index + 1}: ${section.title}`,
          isHeading: true,
          isSelected: headerSelected,
        };

        return [...acc, sectionHeader, ...questions];
      },
      [
        {
          id: 'mandatoryFields',
          label: 'Mandatory Fields',
          isSelected: true,
          isEnabled: false,
          isHeading: true,
          isDownloadOnly: true,
        },
      ],
    )
    .sort((key1, key2) => {
      const sortKey1 = KEY_ID_SORTS[key1.id] ?? Number.MAX_SAFE_INTEGER;
      const sortKey2 = KEY_ID_SORTS[key2.id] ?? Number.MAX_SAFE_INTEGER;

      return sortKey1 - sortKey2;
    });

  const hasScoringOutcome = templateHasScoringOutcome(template);
  return hasScoringOutcome
    ? exportableQuestions
    : exportableQuestions.filter((question) => question.id !== 'Outcome');
};

const sectionInputQuestions = (
  question: SectionQuestionDefinition,
  showHiddenQuestions: boolean,
  parentHeadingId: string,
  existingKeys?: ExportableQuestion[],
) =>
  EXPORTABLE_QUESTIONS.reduce<ExportableQuestion[]>((acc, key) => {
    const item: any = (question as any)[key];

    if (!item) return acc;
    if (!isExportableInput(item)) return acc;

    const isHiddenByDefault = !isDefaultVisible(item);
    if (!showHiddenQuestions && isHiddenByDefault) return acc;

    return item.fields
      ? acc.concat(
          item.fields
            .filter(isDefaultVisible)
            .filter(isExportableInput)
            .map((field: CommonDefinition) =>
              getExportableQuestionsFromItem(
                key,
                field,
                showHiddenQuestions,
                isHiddenByDefault,
                parentHeadingId,
                existingKeys,
              ),
            )
            .flat(),
        )
      : acc.concat(
          getExportableQuestionsFromItem(
            key,
            item,
            showHiddenQuestions,
            isHiddenByDefault,
            parentHeadingId,
            existingKeys,
          ),
        );
  }, []);

const isDefaultVisible = (item: CommonDefinition) =>
  getProp(item, 'visible', true) &&
  !getProp(item, 'showOnSwitch') &&
  !getProp(item, 'showOnAnswer') &&
  !item.showOnReportingDateMonth;

const isExportableInput = (item: DropdownAnswerDefinition) =>
  getProp(item, 'dropdownListName')
    ? DROPDOWN_RESERVED_IDS.includes(item.id)
    : true;

const getExportableQuestionsFromItem = (
  key: QuestionTypes,
  definition: CommonDefinition,
  showHiddenQuestions: boolean,
  isHiddenByDefault: boolean,
  parentHeadingId: string,
  existingKeys?: ExportableQuestion[],
) => {
  const answerOptions = getSupportedAnswersForQuestion[key]?.(definition);

  const isReservedId = DROPDOWN_RESERVED_IDS.includes(definition.id);
  if (isReservedId)
    return [
      {
        id: definition.id,
        label: str.removeMarkdownAndSpecialCharacters(definition.label, {
          removeTrailingQuestionMark: true,
        }),
        isEnabled: false,
        isSelected: true,
        isHiddenByDefault,
        answerOptions,
        parentHeadingId,
        isDownloadOnly: true,
      },
    ];

  const id = key === 'score' ? 'Outcome' : definition.id;
  const label = str.removeMarkdownAndSpecialCharacters(
    definition.label
      ? str.humanize(definition.label, true, false)
      : str.humanize(id, true, false),
    { removeTrailingQuestionMark: true },
  );

  const result = [
    {
      id,
      label,
      answerOptions,
      isSelected: existingKeys?.find((existingKey) => existingKey.id === id)
        ?.isSelected,
      isHiddenByDefault,
      parentHeadingId,
    },
  ];

  if (!showHiddenQuestions) return result;
  if (!isOptionsAnswerDefinition(definition)) return result;

  return result.concat(
    getIssueInsightQuestions(definition.answers, id, label, parentHeadingId),
  );
};

const getSupportedAnswersForQuestion: {
  [key: string]: (item: CommonDefinition) => string[];
} = {
  text: (item: InputDefinition) => getInputDefinitionSupportedAnswer(item),
  options: (item: OptionsDefinition) => [
    getProp(item, 'multiSelect', false)
      ? ANSWER_TYPE_LABELS.multiSelect
      : ANSWER_TYPE_LABELS.option,
    ...(item.answers?.map((answer) => answer.label) ?? []),
  ],
  slider: (item: SliderDefinition) => [
    ANSWER_TYPE_LABELS.option,
    ...(item.marks?.map((mark) => mark.label) ?? []),
  ],
  switch: () => [ANSWER_TYPE_LABELS.option, 'Yes', 'No'],
  score: () => [ANSWER_TYPE_LABELS.score, 'Pass', 'Fail', 'Provisional'],
};

const getInputDefinitionSupportedAnswer = (item: InputDefinition): string[] => {
  if (isSelectAnswerDefinition(item)) {
    const options = item.options?.map((option) =>
      isRichSelectOption(option) ? (option?.label ?? option?.id) : option,
    );
    return [ANSWER_TYPE_LABELS.option, ...options];
  }
  if (item.kind === 'date') return [ANSWER_TYPE_LABELS.date];
  if (item.kind === 'number') return [ANSWER_TYPE_LABELS.number];

  const dropdownListName = getProp(
    item as DropdownAnswerDefinition,
    'dropdownListName',
  );
  if (dropdownListName) {
    return dropdownListName.toUpperCase() === 'PEOPLE'
      ? [ANSWER_TYPE_LABELS.people]
      : [ANSWER_TYPE_LABELS.team];
  }
  return [ANSWER_TYPE_LABELS.text];
};

import {
  FormAnswerAutomatedAction,
  FormIssueInsightAnswerDefinition,
  ServerAutomatedAction,
} from '../types.ts';

type MapAutomatedActionArgs = {
  answerKey: string;
  answerValue: string;
  input: ServerAutomatedAction;
  insightId?: string;
  issueInsightAnswerDefinitions: Record<
    string,
    FormIssueInsightAnswerDefinition[]
  >;
  issueId?: string;
  questionKeys: string[];
};

export const mapAutomatedAction = ({
  answerKey,
  answerValue,
  input,
  insightId,
  issueInsightAnswerDefinitions,
  issueId,
  questionKeys,
}: MapAutomatedActionArgs): FormAnswerAutomatedAction => {
  const issueInsightAnswerDefinition = issueInsightAnswerDefinitions[
    answerKey
  ].find((def) => def.optionId === answerValue);

  const classifications =
    issueInsightAnswerDefinition.classification?.options ?? [];

  return {
    id: input.id,
    description: input.description,
    label: input.label,
    createdActionId: input.createdActionId,
    insightId,
    issueId,
    formQuestionIndex: questionKeys.findIndex((key) => key === answerKey),
    issueInsightClassificationIndex: classifications.findIndex(
      (c) => c === input.automatedAction.matchIssueClassification,
    ),
  };
};

import { FormIssue, IssueCause } from '@se/data/forms/types.ts';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import {
  CheckboxIssueInsightOption,
  IOptionsAnswerLine,
} from '../../types/types.ts';
import {
  IFormInstance,
  ReduxFormInstanceServerLoaded,
} from '../form-instance/types.ts';
import { GlobalFormsState } from '../store.ts';
import * as issueSlice from './issueSlice.ts';

export function loadedComplianceIssuesOnInstanceLoadedEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    filter(() => state$.value.tenantState.tenant?.module === 'compliance'),
    map((action) =>
      issueSlice.loadedIssues(
        getComplianceIssuesFromInstance(action.payload.instance),
      ),
    ),
  );
}

export const getComplianceIssuesFromInstance = (instance: IFormInstance) => {
  if (!instance)
    return {
      issues: new Array<FormIssue>(),
      issueCauses: new Array<IssueCause>(),
      canView: false,
      loadError: false,
    };

  const { answers, lines } = instance;
  const issueAnswers = Object.entries(answers)
    .filter(([, answer]) => Boolean(answer.issue))
    .map(([key, answer]) => ({ key, answer }));

  if (!issueAnswers.length || !lines)
    return { issues: [], issueCauses: [], canView: false, loadError: false };

  const issues = issueAnswers
    .map(({ key, answer }): FormIssue => {
      const question = lines[key] as IOptionsAnswerLine;
      const optionsDefinition =
        question.options as CheckboxIssueInsightOption[];
      if (!optionsDefinition) return undefined;

      const answerValue = answer.value?.toString();
      const optionDefinition = optionsDefinition?.find(
        (definition) => definition.id?.toString() === answerValue,
      );
      if (!optionDefinition) return undefined;

      const issueDefinition = optionDefinition.issue;
      if (!issueDefinition) return undefined;

      return {
        answerId: answer.id,
        id: answer.issue.id,
        coaching: {
          requirement: issueDefinition.coachingRequirement,
          specifyCauseAndCoaching: {
            isSelected: issueDefinition.coachingRequirement === 'MANDATORY',
            canChange: issueDefinition.coachingRequirement === 'RECOMMENDED',
          },
        },
        label: answer.issue.label,
        formSectionName: issueDefinition.formSectionName,
        formQuestionIndex: Object.keys(lines).findIndex(
          (lineKey) => lineKey === key,
        ),
        formQuestionText: issueDefinition.formQuestionText,
        formAnswerSelected: issueDefinition.formAnswerSelected,
        formLineId: issueDefinition.formLineId,
        parentSectionLineId: issueDefinition.parentSectionLineId,
        notes: answer.issue.notes ?? undefined,
        notesDefinition: issueDefinition.notesDefinition,
        classificationDefinition: issueDefinition.classificationDefinition,
        classifications: (answer.issue.classifications ?? []).map(
          (classification) => ({
            index: (
              issueDefinition.classificationDefinition.options ?? []
            ).findIndex((c) => c === classification),
            value: classification,
          }),
        ),
        issueCauses: { isSpinning: false, causes: [] },
        issueCoachingRequired: issueDefinition.coachingRequired,
        errors: { coaching: {}, compliance: {} },
        isLoading: false,
      };
    })
    .filter(Boolean);

  return {
    issues,
    issueCauses: new Array<IssueCause>(),
    canView: true,
    loadError: false,
  };
};

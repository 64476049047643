import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormTemplateInsight, InsightType } from '../types.ts';

type GetFormTemplateInsightsArgs = {
  templateId: string;
  showAll?: boolean;
};

export const getFormTemplateInsights = async (
  client: IGraphQLClient,
  args: GetFormTemplateInsightsArgs,
): Promise<QueryResult<FormTemplateInsight[]>> => {
  try {
    const response = await client.query<{
      forms: {
        formTemplateInsights: Array<{
          label: string;
          type: InsightType;
          inCurrentDefinition: boolean;
          classifications: Array<{
            classification: string;
            inCurrentDefinition: boolean;
          }>;
        }>;
      };
    }>({
      query: gql`
        query FormTemplateInsights($templateId: ID!, $showAll: Boolean) {
          forms {
            formTemplateInsights(templateId: $templateId, showAll: $showAll) {
              label
              type
              inCurrentDefinition
              classifications {
                classification
                inCurrentDefinition
              }
            }
          }
        }
      `,
      variables: { templateId: args.templateId, showAll: args.showAll },
      fetchPolicy: 'network-only',
    });

    const formTemplateInsights = response.data.forms.formTemplateInsights;

    if (!formTemplateInsights) {
      log.error(
        `Failed to retrieve form template insights for ${args.templateId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: formTemplateInsights.map((templateInsight) => ({
        insightLabel: templateInsight.label,
        type: templateInsight.type,
        inCurrentDefinition: templateInsight.inCurrentDefinition,
        classifications: templateInsight.classifications.map((c) => ({
          classification: c.classification,
          inCurrentDefinition: c.inCurrentDefinition,
        })),
      })),
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form template insights for ${args.templateId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ModuleType, OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormTemplate } from '../types.ts';

type GetTemplatesArgs = {
  createInstanceOnly?: boolean;
  fetchAllPages?: boolean;
  hasInsightsOnly?: boolean;
  hasIssuesOnly?: boolean;
  includeDefinition?: boolean;
  module: ModuleType;
  name?: string;
  orderBy?: OrderBy[];
  pageNumber?: number;
  showAll?: boolean;
};

export type GetFormTemplatesResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  formTemplates: FormTemplate[];
};

export const getFormTemplates = async (
  client: IGraphQLClient,
  args: GetTemplatesArgs,
): Promise<QueryResult<GetFormTemplatesResponseDto>> => {
  if (!args.fetchAllPages) return getFormTemplatesPage(client, args);

  try {
    const allFormTemplates = await getFormTemplatesRecursive(client, args);
    return {
      isSuccess: true,
      data: {
        formTemplates: allFormTemplates,
        pageInfo: { hasNextPage: false, currentPage: 1 },
      },
    };
  } catch (error) {
    log.error(error);
    return { isSuccess: false, error, errorReason: 'UNKNOWN' };
  }
};

const getFormTemplatesRecursive = async (
  client: IGraphQLClient,
  args: GetTemplatesArgs,
  templates: FormTemplate[] = [],
  pageNumber = 1,
): Promise<FormTemplate[]> => {
  const response = await getFormTemplatesPage(client, {
    module: args.module,
    name: args.name,
    createInstanceOnly: args.createInstanceOnly,
    hasInsightsOnly: args.hasInsightsOnly,
    hasIssuesOnly: args.hasIssuesOnly,
    showAll: args.showAll,
    includeDefinition: args.includeDefinition,
    orderBy: args.orderBy,
    pageNumber,
  });

  if (!response.isSuccess) {
    throw new Error(`Failed to retrieve form templates for page ${pageNumber}`);
  }

  const nextTemplates = templates.concat(response.data.formTemplates);

  const pageInfo = response.data.pageInfo;
  if (!pageInfo.hasNextPage) return nextTemplates;

  return getFormTemplatesRecursive(
    client,
    args,
    nextTemplates,
    pageInfo.currentPage + 1,
  );
};

const getFormTemplatesPage = async (
  client: IGraphQLClient,
  args: GetTemplatesArgs,
): Promise<QueryResult<GetFormTemplatesResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formTemplates: {
          nodes: FormTemplate[];
          pageInfo: { hasNextPage: boolean; currentPage: number };
        };
      };
    }>({
      query: gql`
        query FormTemplates(
          $createInstanceOnly: Boolean
          $hasInsightsOnly: Boolean
          $hasIssuesOnly: Boolean
          $pageNumber: Int
          $name: String
          $showAll: Boolean
          $orderBy: [OrderByInput!]
        ) {
          forms {
            formTemplates(
              createInstanceOnly: $createInstanceOnly
              hasInsightsOnly: $hasInsightsOnly
              hasIssuesOnly: $hasIssuesOnly
              orderBy: $orderBy
              pagination: { pageNumber: $pageNumber, size: 100 }
              name: $name
              showAll: $showAll
            ) {
              pageInfo {
                hasNextPage
                currentPage
              }
              nodes {
                id
                name
                currentDefinitionId
                visibilityLimitations
                status
                updatedAt
                category {
                  id
                  name
                }
                ${
                  args.includeDefinition
                    ? `currentDefinition { definition }`
                    : ''
                }
              }
            }
          }
        }
      `,
      variables: {
        module: args.module,
        name: args.name,
        pageNumber: args.pageNumber,
        showAll: args.showAll,
        createInstanceOnly: args.createInstanceOnly,
        hasInsightsOnly: args.hasInsightsOnly,
        hasIssuesOnly: args.hasIssuesOnly,
        orderBy: args.orderBy,
      },
      fetchPolicy: 'cache-first',
    });

    const { pageInfo, nodes } = response.data.forms.formTemplates;

    if (!nodes) {
      log.error(`Failed to retrieve form templates for ${module}`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        formTemplates: nodes.map((node) => ({
          id: node.id,
          name: node.name,
          category: node.category && {
            id: node.category.id,
            name: node.category.name,
          },
          currentDefinition: node.currentDefinition && {
            definition: node.currentDefinition.definition,
          },
          visibilityLimitations: node.visibilityLimitations,
          currentDefinitionId: node.currentDefinitionId,
          status: node.status,
          updatedAt: node.updatedAt,
        })),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form templates for ${module} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

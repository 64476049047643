import {
  addFormActionNote,
  cancelFormAction,
  completeFormAction,
  createFormAction,
  revertFormAction,
  updateFormActionAnswerDropdown,
  updateFormActionAnswerText,
  updateFormActionAssignedToPersonId,
  updateFormActionDescription,
  updateFormActionDueBy,
  updateFormActionInsightId,
  updateFormActionIssueId,
  updateFormActionVerificationNotes,
  updateFormActionVerificationStatus,
} from './action.ts';
import {
  createFormAnswerWithIssueInsight,
  updateFormAnswerWithIssueInsight,
} from './answer.ts';
import {
  createInsightAutomatedAction,
  createIssueAutomatedAction,
  deleteAutomatedAction,
  updateAutomatedActionDescription,
} from './automatedAction.ts';
import { formsBulkUploadImportFile } from './formsBulkUploadImportFile.ts';
import {
  activateGoalSchedule,
  createGoalSchedule,
  deactivateGoalSchedule,
  updateGoalSchedule,
  updateGoalScheduleEndDate,
} from './goalSchedule.ts';
import { updateFormAnswerInsightClassifications } from './insightClassifications.ts';
import { updateFormAnswerInsightNotes } from './insightNotes.ts';
import {
  createInstanceSchedule,
  deactivateInstanceScheduleStatus,
  updateInstanceSchedule,
  updateInstanceScheduleEndDate,
} from './instanceSchedule.ts';
import { updateFormAnswerIssueClassifications } from './issueClassifications.ts';
import { updateFormAnswerIssueNotes } from './issueNotes.ts';

export const formsMutation = {
  activateGoalSchedule,
  addFormActionNote,
  cancelFormAction,
  completeFormAction,
  createFormAction,
  createFormAnswerWithIssueInsight,
  createGoalSchedule,
  createInsightAutomatedAction,
  createInstanceSchedule,
  createIssueAutomatedAction,
  deactivateGoalSchedule,
  deactivateInstanceScheduleStatus,
  deleteAutomatedAction,
  formsBulkUploadImportFile,
  revertFormAction,
  updateAutomatedActionDescription,
  updateFormActionAnswerDropdown,
  updateFormActionAnswerText,
  updateFormActionAssignedToPersonId,
  updateFormActionDescription,
  updateFormActionDueBy,
  updateFormActionInsightId,
  updateFormActionIssueId,
  updateFormActionVerificationNotes,
  updateFormActionVerificationStatus,
  updateFormAnswerInsightClassifications,
  updateFormAnswerInsightNotes,
  updateFormAnswerIssueClassifications,
  updateFormAnswerIssueNotes,
  updateFormAnswerWithIssueInsight,
  updateGoalSchedule,
  updateGoalScheduleEndDate,
  updateInstanceSchedule,
  updateInstanceScheduleEndDate,
};

import gql from 'graphql-tag';

const queryAttendance = (include: boolean) =>
  include
    ? `attendees {
          nodes {
            id
            name
            attended
            followUp
          }
        }`
    : '';

type QueryInstanceLoadArgs = {
  includeAttendance: boolean;
};

export const queryInstanceLoad = (args: QueryInstanceLoadArgs) =>
  gql`
  query FormInstance($instanceId: ID!) {
    forms {
      formInstance(id: $instanceId) {
        id
        reportingDate
        createdAt
        definition {
          id
          definition
        }
        template {
          id
          name
          category {
            id
            name
          }
        }
        answers(orderBy: { fieldName: "createdAt", direction: Descending }) {
          nodes {
            id
            key
            value
            createdAt
            displayValue
            group
            issue {
              id
              classifications
              label
              notes
            }
            insight {
              id
              classifications
              label
              notes
            }
            automatedActions {
              id
              description
              label
              createdActionId
              automatedAction {
                matchInsightClassification
                matchIssueClassification
              }
            }
          }
        }
        assignedTo {
          id
          name
          positionTitle
        }
        subject {
          id
          name
          type
          positionTitle
          path
        }
        status
        updatedAt
        permissions {
          edit
          archive
          cancel
          assignedToSignOff
          assignedToRevertSignOff
          subjectSignOff
          subjectRevertSignOff
          appeal
        }
        documents {
          id
          createdAt
          createdBy {
            id
            name
          }
          documentName
          documentSize
        }
        signOffs {
          id
          name
          occurredAt
          authorisedBy {
            id
            name
          }
        }
        statusNotes {
          appealCategory
          appealOutcome
          appealOutcomeReason
          appealReason
          appealResponse
        }
        score {
          remediationResult {
            comments
            outcome
          }
        }
        ${queryAttendance(args.includeAttendance)}
      }
    }
  }
`;

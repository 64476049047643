import { isAnyOf } from '@reduxjs/toolkit';
import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, filter, of } from 'rxjs';
import { automatedActionSlice } from '../automatedAction/index.ts';
import { GlobalFormsState } from '../store.ts';
import * as insightSlice from './insightSlice.ts';

export const getInsightClassificationsObservable = (
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) =>
  action$.pipe(
    filter(
      isAnyOf(
        insightSlice.addClassification.match,
        insightSlice.deleteClassification.match,
        insightSlice.saveClassifications.match,
      ),
    ),
    filter((action) =>
      state$.value.formInsight.insights.some(
        (insight) => insight.answerId === action.payload.answerId,
      ),
    ),
  );

export const getInsightNotesObservable = (
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) =>
  action$.pipe(
    filter(insightSlice.setNotes.match),
    filter(
      (action) =>
        Boolean(action.payload.notes) &&
        state$.value.formInsight.insights.some(
          (insight) => insight.answerId === action.payload.answerId,
        ),
    ),
  );

export const handleInsightNotesChangeAction = async (
  payload: { answerId: string; instanceId: string; notes: string },
  client: IGraphQLClient,
) => {
  const response = await formsMutation.updateFormAnswerInsightNotes(client, {
    answerId: payload.answerId,
    instanceId: payload.instanceId,
    notes: payload.notes,
  });

  return response.isSuccess
    ? EMPTY
    : of(
        insightSlice.setErrors({
          answerId: payload.answerId,
          notes: 'There was a problem saving this response.',
        }),
      );
};

export const handleInsightClassificationsChangeAction = async (
  instanceId: string,
  answerId: string,
  client: IGraphQLClient,
  state$: StateObservable<GlobalFormsState>,
) => {
  const insight = state$.value.formInsight.insights.find(
    (i) => i.answerId === answerId,
  );

  const response = await formsMutation.updateFormAnswerInsightClassifications(
    client,
    {
      answerId,
      instanceId,
      insightId: insight.id,
      classifications: (insight.classifications ?? []).map(
        ({ value }) => value,
      ),
      questionKeys: state$.value.formInstance.questionKeys,
      issueInsightAnswerDefinitions:
        state$.value.formInstance.issueInsightAnswerDefinitions,
    },
  );

  if (!response.isSuccess)
    return of(
      insightSlice.setErrors({
        answerId,
        classification: 'There was a problem saving this response.',
      }),
    );

  return of(
    automatedActionSlice.setAnswerAutomatedActions({
      answerId,
      automatedActions: response.data.answerAutomatedActions,
    }),
  );
};

import { ServerAutomatedAction } from '@se/data/forms/types.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import momentTz from 'moment-timezone';
import { isLineVisible } from '../hooks/util.ts';
import {
  FormAnswerByKey,
  FormLineById,
  IFormInstance,
} from '../redux/form-instance/types.ts';
import { IFormScoreState } from '../redux/form-score/types.ts';
import {
  FormAnswer,
  FormLineComponent,
  IInputsLine,
  ISignoffLine,
  InputItem,
} from '../types/types.ts';

export const validateRequiredFields = (
  instance: IFormInstance,
  score: IFormScoreState,
  tenantTimezone: string,
): string[] => {
  if (!instance) return [];

  const lines = Object.values(instance.lines);
  const answers = instance.answers;
  return lines
    .map((line) => {
      if (!isLineVisible(line, answers, tenantTimezone)) return;

      if (line.type === 'inputs')
        return line.inputs
          .map((input) => {
            if (!input.isRequired) return;

            const answer = instance.answers[input.id];
            if (!answer) return input.id;
            if (answer.value === '') return input.id;
          })
          .filter(Boolean);

      if (!line.isRequired) return;

      if (line.id === 'scoreOverrule') {
        if (!score) return 'scoreOverrule';

        const overruledResult = score.overruledResult;
        if (!overruledResult || overruledResult === 'N/A')
          return 'scoreOverrule';

        if (!score.errors) return;

        const errorCount = Object.values(score.errors).filter(Boolean).length;
        if (errorCount > 0) return 'scoreOverrule';

        return;
      }

      const answer = instance.answers[line.id];
      if (!answer?.value) return line.id;

      if (line.type === 'switchAnswer' && answer.value === 'false')
        return line.id;

      if (line.type === 'sliderAnswer' && answer.value === '') return line.id;
    })
    .flat()
    .filter(Boolean);
};

export const getUserAppealPermissions = (
  instance?: IFormInstance,
  personId?: string,
) => ({
  canAppealAsSubject: canAppealAsSubject(instance, personId),
  canAppealAsManager: canAppealAsManager(instance, personId),
  onlyManagerCanAppeal: onlyManagerCanAppeal(instance, personId),
  cannotAppeal: cannotAppeal(instance),
});

const canAppealAsSubject = (instance?: IFormInstance, personId?: string) => {
  const formHasAppealPermission = (instance?.lines?.signoff as ISignoffLine)
    ?.canAppeal;
  if (!formHasAppealPermission) return false;

  const formIsPending = instance?.status.status === 'Pending';
  if (!formIsPending) return false;

  const userHasAppealPermission = instance?.permissions.appeal;
  if (!userHasAppealPermission) return false;

  const userIsFormSubject = personId === instance?.subject?.id;
  if (!userIsFormSubject) return false;

  return true;
};

const canAppealAsManager = (instance?: IFormInstance, personId?: string) => {
  const formHasAppealPermission = (instance?.lines?.signoff as ISignoffLine)
    ?.canAppeal;
  if (!formHasAppealPermission) return false;

  const formIsPending = instance?.status.status === 'Pending';
  if (!formIsPending) return false;

  const userHasAppealPermission = instance?.permissions.appeal;
  if (!userHasAppealPermission) return false;

  const userIsFormSubject = personId === instance?.subject?.id;
  if (userIsFormSubject) return false;

  return true;
};

const onlyManagerCanAppeal = (instance?: IFormInstance, personId?: string) => {
  const formHasAppealPermission = (instance?.lines?.signoff as ISignoffLine)
    ?.canAppeal;
  if (!formHasAppealPermission) return false;

  const formIsPending = instance?.status.status === 'Pending';
  if (!formIsPending) return false;

  const preventSubjectAppeal = (instance?.lines?.signoff as ISignoffLine)
    ?.preventSubjectAppeal;
  if (!preventSubjectAppeal) return false;

  const userHasAppealPermission = instance?.permissions.appeal;
  if (userHasAppealPermission) return false;

  const userIsFormSubject = personId === instance?.subject?.id;
  if (!userIsFormSubject) return false;

  return true;
};

const cannotAppeal = (instance?: IFormInstance) => {
  const formTemplateCanAppeal = (instance?.lines?.signoff as ISignoffLine)
    ?.canAppeal;
  if (!formTemplateCanAppeal) return true;

  const instancePending = instance?.status.status === 'Pending';
  if (!instancePending) return true;

  const canAppeal = instance?.permissions.appeal;
  const preventSubjectAppeal = (instance?.lines?.signoff as ISignoffLine)
    ?.preventSubjectAppeal;

  return !canAppeal && !preventSubjectAppeal;
};

export type ServerAnswer = {
  id: string;
  key: string;
  value: string;
  createdAt: string;
  displayValue: string;
  group?: string;
  issue?: {
    id: string;
    classifications: string[];
    label: string;
    notes: string;
  };
  insight?: {
    id: string;
    classifications: string[];
    label: string;
    notes: string;
  };
  automatedActions?: ServerAutomatedAction[];
};

export const convertServerAnswersToInstanceAnswers = (
  answers: ServerAnswer[],
  lines: FormLineById,
  reportingDate?: string,
): FormAnswerByKey => {
  if (!answers?.length) return {};

  const reportingDateAnswer = reportingDate
    ? ({
        reportingDate: {
          id: '00000000-0000-0000-0000-000000000000',
          lineId: 'reportingDate',
          type: 'inputs',
          subType: 'dateAnswer',
          value: reportingDate,
        },
      } as FormAnswerByKey)
    : {};

  return answers
    .filter(
      (answer) =>
        hasLineForKey(lines, answer.key) && answer.key !== 'reportingDate',
    )
    .map((answer): FormAnswer => {
      const line =
        findLineById(answer.key, lines) || findLineByInputId(answer.key, lines);

      const input = findInputById(answer.key, lines);

      if (line.type === 'optionsAnswer') {
        return {
          id: answer.id,
          lineId: line.id,
          type: line.type,
          group: answer.group,
          value: answer.value,
          subType: line.multiSelect ? 'CHECKBOX' : 'RADIO',
          issue: answer.issue,
          insight: answer.insight,
        };
      }

      return {
        id: answer.id,
        lineId: input?.id ?? line.id,
        type: 'inputs',
        group: answer.group,
        value: answer.value,
        displayValue: answer.displayValue,
        subType: input?.type,
      };
    })
    .reduce((a, v) => ({ ...a, [v.lineId]: v }), reportingDateAnswer);
};

/**
 * Gets Due Date with time component for supplied `date`.
 * Sets to the end of day for the specified timezone
 */
export function dueDateForDay(date: string, timezone: string) {
  return momentTz(date).tz(timezone).endOf('day');
}

const getExpressionParts = (expression?: string) => {
  if (!expression) return;

  const parts = expression.split(' ');
  if (!parts.length) return;
  if (parts.length !== 2) return;

  const [amountString, unit] = parts;

  const amount = parseInt(amountString);
  if (isNaN(amount)) return;

  if (!unit) return;

  if (['day', 'days'].includes(unit.toLowerCase()))
    return { amount, unit: 'days' } as const;

  if (['week', 'weeks'].includes(unit.toLowerCase()))
    return { amount, unit: 'weeks' } as const;

  if (['month', 'months'].includes(unit.toLowerCase()))
    return { amount, unit: 'months' } as const;
};

export function getDefaultDueByForActions(
  module: ModuleType,
  timezone = 'Etc/UTC',
  defaultDueByExpression?: string,
) {
  const endToday = momentTz().tz(timezone, false).endOf('day');

  const expressionParts = getExpressionParts(defaultDueByExpression);
  if (expressionParts)
    return endToday
      .add(expressionParts.amount, expressionParts.unit)
      .toISOString();

  if (module === 'compliance') return endToday.add(30, 'days').toISOString();
  return endToday.add(1, 'week').toISOString();
}

export type FormatDueByArgs = {
  dueBy?: string;
  showDistantDates?: boolean;
  capitalized?: boolean;
  timezone: string;
};

/**
 * Gets a human readable string for a given due date.
 */
export const formatDueBy = ({
  dueBy,
  showDistantDates,
  capitalized = true,
  timezone,
}: FormatDueByArgs) => {
  if (!dueBy) {
    return undefined;
  }

  const startToday = momentTz().tz(timezone, false).startOf('day');
  const startDueBy = momentTz(dueBy).tz(timezone, false).startOf('day');

  const diffDays = startDueBy.diff(startToday, 'days');

  switch (diffDays) {
    case 1:
      return getDueByWithCorrectCase('Due Tomorrow', capitalized);
    case -1:
      return getDueByWithCorrectCase('Due Yesterday', capitalized);
    case 0:
      return getDueByWithCorrectCase('Due Today', capitalized);
  }

  const absDiffDays = Math.abs(diffDays);
  const isOverdue = diffDays < 0;

  if (absDiffDays <= 7)
    return getDueByWithCorrectCase(
      `Due ${startDueBy.from(startToday)}`,
      capitalized,
    );

  if (absDiffDays <= 28) {
    const weeks = Math.round(absDiffDays / 7);
    const weeksString = weeks === 1 ? '1 week' : `${weeks} weeks`;

    const dueByString = isOverdue
      ? `Due ${weeksString} ago`
      : `Due in ${weeksString}`;

    return getDueByWithCorrectCase(dueByString, capitalized);
  }

  if (isOverdue)
    return getDueByWithCorrectCase('Due more than 4 weeks ago', capitalized);

  if (showDistantDates) {
    // Month values should remain uppercase
    return `${capitalized ? 'Due' : 'due'} ${startDueBy.format('LL')}`;
  }

  return undefined;
};

const getDueByWithCorrectCase = (
  capitalizedValue: string,
  capitalize: boolean,
) => (capitalize ? capitalizedValue : capitalizedValue.toLowerCase());

const hasLineForKey = (lines: FormLineById, key: string) =>
  Boolean(findLineById(key, lines) || findLineByInputId(key, lines));

const findLineById = (
  id: string,
  lines: FormLineById,
): FormLineComponent | undefined =>
  Object.values(lines).find((line) => line.id === id);

const findLineByInputId = (
  inputId: string,
  lines: FormLineById,
): FormLineComponent | undefined =>
  Object.values(lines).find(
    (line) =>
      line.type === 'inputs' &&
      (line.inputs || []).some((input) => input.id === inputId),
  );

const findInputById = (
  inputId: string,
  lines: FormLineById,
): InputItem | undefined =>
  Object.values(lines)
    .filter((line) => line.type === 'inputs')
    .flatMap((lineWithInputs) => (lineWithInputs as IInputsLine).inputs || [])
    .find((input) => input.id === inputId);

export const hasIssueCoaching = (
  instance: IFormInstance,
  includeCheckIn = true,
) => {
  if (!instance) return false;

  const formLines = instance.lines;
  if (!formLines) return false;

  return Object.keys(formLines).some((key) => {
    if (includeCheckIn && key === 'issueCheckIn') return true;
    return key === 'issueCoachingPlan';
  });
};

export const hasIssues = (instance: IFormInstance) => {
  if (!instance) return false;

  const formLines = instance.lines;
  if (!formLines) return false;

  return Object.values(formLines).some((line) => {
    if (line.type !== 'optionsAnswer') return false;
    if (!line.options?.length) return false;

    return line.options.some((o) => Boolean(o.issue));
  });
};

export const hasInsights = (instance: IFormInstance) => {
  if (!instance) return false;

  const formLines = instance.lines;
  if (!formLines) return false;

  return Object.values(formLines).some((line) => {
    if (line.type !== 'optionsAnswer') return false;
    if (!line.options?.length) return false;

    return line.options.some((o) => Boolean(o.insight));
  });
};

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormActionPlanAction,
  FormInstanceActionPlanType,
} from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { getCategoryOverdueLabel } from '../util.ts';
import { Action } from './Action.tsx';
import { AddAction } from './AddAction.tsx';
import { Section } from './Section.tsx';

export type StandaloneActionsProps = {
  actions: FormActionPlanAction[];
  canCreateActions: boolean;
  categoryGrouping: boolean;
  instanceId: string;
  isDefaultExpanded: boolean;
  typeFilter?: FormInstanceActionPlanType;
};

export const StandaloneActions: React.FC<StandaloneActionsProps> = ({
  actions,
  canCreateActions,
  categoryGrouping,
  instanceId,
  isDefaultExpanded,
  typeFilter,
}) => {
  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const overdueLabel = useMemo(() => {
    const numOverdueActions = actions.filter(
      (action) => action.status === 'Overdue',
    ).length;

    return numOverdueActions
      ? getCategoryOverdueLabel(0, numOverdueActions, actionLabel)
      : undefined;
  }, [actionLabel, actions]);

  const addNewActionText = useMemo(
    () =>
      actions.length
        ? `Add another ${actionLabel}.`
        : `Add a new ${actionLabel}.`,
    [actionLabel, actions.length],
  );

  const hasActions = actions.length > 0;

  const elContent = (
    <>
      {!hasActions && !canCreateActions && (
        <Text color={color.format(-0.5)} italic={true}>
          {`No existing ${str.plural(actionLabel)}.`}
        </Text>
      )}
      {actions.map((action) => (
        <Action
          key={`Action-${action.id}`}
          action={action}
          source={{ type: 'FORM', instanceId }}
        />
      ))}
      {typeFilter !== 'OnlyExisting' && canCreateActions && (
        <AddAction
          message={addNewActionText}
          instanceId={instanceId}
          style={styles.addAction(hasActions)}
          showAutomatedActions={true}
        />
      )}
    </>
  );

  return categoryGrouping ? (
    <Section
      id={'standaloneActions'}
      title={`Coaching Actions (${actions.length})`}
      overdueLabel={overdueLabel}
      isDefaultExpanded={isDefaultExpanded}
      isArchived={false}
    >
      {elContent}
    </Section>
  ) : (
    elContent
  );
};

const styles = {
  addAction: (hasActions: boolean) =>
    hasActions
      ? css({
          padding: '12px 0 0 0',
          marginLeft: -3,
        })
      : css({
          marginLeft: -8,
        }),
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';

export type UpdateFormAnswerInsightNotesArgs = {
  instanceId: string;
  answerId: string;
  notes: string;
};

export const updateFormAnswerInsightNotes = async (
  client: IGraphQLClient,
  args: UpdateFormAnswerInsightNotesArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormAnswerInsightNotes: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormAnswerInsightNotes(
          $answerId: ID!
          $instanceId: ID!
          $notes: String!
        ) {
          forms {
            updateFormAnswerInsightNotes(
              input: {
                instanceId: $instanceId
                answerId: $answerId
                notes: $notes
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        answerId: args.answerId,
        instanceId: args.instanceId,
        notes: args.notes,
      },
    });

    return { isSuccess: response.data.forms.updateFormAnswerInsightNotes.ok };
  } catch (err) {
    log.error(
      `Unable to update form answer insight notes for instance ${args.instanceId} and answer ${args.answerId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

import { GlobalFormsState } from '../store.ts';
import * as insightSlice from './insightSlice.ts';

export const insightValidationErrorActions = (state: GlobalFormsState) => {
  if (!state.formInsight.insights.length) return;

  return state.formInsight.insights
    .map((insight) => {
      const notesMissing = Boolean(
        !insight.notes && insight.notesDefinition.required,
      );
      const classificationMissing = Boolean(
        insight.classificationDefinition?.required &&
          insight.classificationDefinition?.options?.length &&
          !insight.classifications?.length,
      );

      if (!notesMissing && !classificationMissing) return undefined;

      return insightSlice.setErrors({
        answerId: insight.answerId,
        classification: classificationMissing ? 'This is required.' : undefined,
        notes: notesMissing ? 'This is required.' : undefined,
      });
    })
    .filter(Boolean);
};

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { mapAutomatedAction } from '../query/automatedActionsUtil.ts';
import {
  FormAnswerAutomatedAction,
  FormIssueInsightAnswerDefinition,
  ServerAutomatedAction,
} from '../types.ts';

export type UpdateFormAnswerIssueClassificationsArgs = {
  instanceId: string;
  answerId: string;
  classifications: string[];
  issueId: string;
  questionKeys: string[];
  issueInsightAnswerDefinitions: Record<
    string,
    FormIssueInsightAnswerDefinition[]
  >;
};

export const updateFormAnswerIssueClassifications = async (
  client: IGraphQLClient,
  args: UpdateFormAnswerIssueClassificationsArgs,
): Promise<
  MutationResult<
    { answerAutomatedActions: FormAnswerAutomatedAction[] } | undefined
  >
> => {
  try {
    const response = await client.mutate<{
      forms: {
        updateFormAnswerIssueClassifications: {
          ok: boolean;
          answer: {
            key: string;
            value: string;
            issue?: { id: string };
            automatedActions?: ServerAutomatedAction[];
          };
        };
      };
    }>({
      mutation: gql`
        mutation UpdateFormAnswerIssueClassifications(
          $answerId: ID!
          $instanceId: ID!
          $classifications: [String!]
        ) {
          forms {
            updateFormAnswerIssueClassifications(
              input: {
                instanceId: $instanceId
                answerId: $answerId
                classifications: $classifications
              }
            ) {
              ok
              answer {
                key
                value
                issue {
                  id
                }
                automatedActions {
                  id
                  description
                  label
                  createdActionId
                  automatedAction {
                    matchInsightClassification
                    matchIssueClassification
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        answerId: args.answerId,
        instanceId: args.instanceId,
        classifications: args.classifications,
      },
    });

    if (!response.data.forms.updateFormAnswerIssueClassifications.ok)
      return { isSuccess: false };

    const answer =
      response.data.forms.updateFormAnswerIssueClassifications.answer;

    return {
      isSuccess: true,
      data: {
        answerAutomatedActions: (answer.automatedActions ?? []).map(
          (automatedAction) =>
            mapAutomatedAction({
              answerKey: answer.key,
              answerValue: answer.value,
              input: automatedAction,
              issueInsightAnswerDefinitions: args.issueInsightAnswerDefinitions,
              issueId: answer.issue.id,
              questionKeys: args.questionKeys,
            }),
        ),
      },
    };
  } catch (err) {
    log.error(
      `Unable to update form answer issue classifications for instance ${args.instanceId} and answer ${args.answerId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

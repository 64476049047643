import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Classification,
  ClassificationsDefinition,
  FormInsight,
  InsightType,
  NotesDefinition,
} from '@se/data/forms/types.ts';
import { ascend, prop, sortWith } from 'ramda';
import { FormAnswer } from '../../types/types.ts';

const sortInsights = sortWith<FormInsight>([
  ascend(prop('formQuestionIndex')),
  ascend(prop('label')),
]);

export type InsightState = {
  isLoading: boolean;
  insights: FormInsight[];
  canView?: boolean;
  loadError?: boolean;
};

const DEFAULT_STATE: InsightState = {
  isLoading: false,
  insights: [],
};

const slice = createSlice({
  name: 'libs/forms/insight',
  initialState: DEFAULT_STATE,
  reducers: {
    addClassification(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        classificationValue: string;
        itemIndex: number;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      const existingClassification = insight.classifications.some(
        (existing) => existing.value === action.payload.classificationValue,
      );
      if (existingClassification) return;

      insight.classifications.push({
        index: action.payload.itemIndex,
        value: action.payload.classificationValue,
      });
      insight.errors.classifications = undefined;
    },
    answerChangedWithNewInsightId(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        instanceId: string;
        toAnswer: FormAnswer;
        insightId: string;
      }>,
    ) {
      return state;
    },
    setErrors(
      state,
      action: PayloadAction<{
        answerId: string;
        classification?: string;
        notes?: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      if (action.payload.classification)
        insight.errors.classifications = action.payload.classification;

      if (action.payload.notes) insight.errors.notes = action.payload.notes;
    },
    existingInsightForAnswerIdLoading(
      state,
      action: PayloadAction<{ answerId: string }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;
      insight.isLoading = true;
    },
    addInsight(
      state,
      action: PayloadAction<{
        answerId: string;
        classificationDefinition: ClassificationsDefinition;
        classifications: Classification[];
        formAnswerSelected: string;
        formLineId: string;
        formQuestionIndex: number;
        formQuestionText: string;
        formSectionName: string;
        id: string;
        label: string;
        notes?: string;
        notesDefinition?: NotesDefinition;
        parentSectionLineId: string;
        type: InsightType;
      }>,
    ) {
      const newInsight = action.payload;
      state.insights.push({
        id: newInsight.id,
        answerId: newInsight.answerId,
        classificationDefinition: newInsight.classificationDefinition,
        classifications: newInsight.classifications,
        errors: {},
        formAnswerSelected: newInsight.formAnswerSelected,
        formLineId: newInsight.formLineId,
        formQuestionIndex: newInsight.formQuestionIndex,
        formQuestionText: newInsight.formQuestionText,
        formSectionName: newInsight.formSectionName,
        label: newInsight.label,
        notes: newInsight.notes,
        notesDefinition: newInsight.notesDefinition,
        parentSectionLineId: newInsight.parentSectionLineId,
        isLoading: false,
        type: newInsight.type,
      });

      state.insights = sortInsights(state.insights);
      state.isLoading = false;
    },
    deleteClassification(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        classificationValue: string;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      insight.classifications = insight.classifications.filter(
        (existing) => existing.value !== action.payload.classificationValue,
      );

      insight.errors.classifications = undefined;
    },
    loadError(state) {
      state.isLoading = false;
      state.loadError = true;
    },
    loadedInsights(
      state,
      action: PayloadAction<{
        insights: FormInsight[];
        canView: boolean;
      }>,
    ) {
      state.isLoading = false;
      state.loadError = false;
      state.insights = sortInsights(action.payload.insights ?? []);
      state.canView = action.payload.canView;
    },
    clearInsights(state) {
      state.isLoading = false;
      state.insights = [];
    },
    loadInsights(state) {
      state.isLoading = true;
      state.insights = [];
    },
    removeInsightByAnswerId(
      state,
      action: PayloadAction<{ answerId: string }>,
    ) {
      state.insights = state.insights.filter(
        ({ answerId }) => answerId !== action.payload.answerId,
      );
    },
    saveClassifications(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      insight.errors.classifications = undefined;
    },
    setNotes(
      state,
      action: PayloadAction<{
        answerId: string;
        instanceId: string;
        notes: string;
        concurrencyId: string;
      }>,
    ) {
      const insight = state.insights.find(
        ({ answerId }) => answerId === action.payload.answerId,
      );
      if (!insight) return;

      insight.notes = action.payload.notes;
      insight.errors.notes = undefined;
    },
  },
});

export const {
  addClassification,
  addInsight,
  answerChangedWithNewInsightId,
  clearInsights,
  deleteClassification,
  existingInsightForAnswerIdLoading,
  loadedInsights,
  loadError,
  loadInsights,
  removeInsightByAnswerId,
  saveClassifications,
  setErrors,
  setNotes,
} = slice.actions;
export const reducer = slice.reducer;
export type State = InsightState;

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';

export type FormAnswerDto = {
  id: string;
  key: string;
  group?: string;
  value?: string;
  displayValue?: string;
  issue?: {
    id: string;
    label: string;
    notes?: string;
    classifications: string[];
  };
  insight?: {
    id: string;
    label: string;
    notes?: string;
    classifications: string[];
  };
};

export const getFormAnswer = async (
  client: IGraphQLClient,
  instanceId: string,
  answerId: string,
): Promise<QueryResult<FormAnswerDto>> => {
  try {
    const response = await client.query<{
      forms: { formAnswer: FormAnswerDto };
    }>({
      query: gql`
        query FormAnswer($instanceId: ID!, $answerId: ID!) {
          forms {
            formAnswer(instanceId: $instanceId, answerId: $answerId) {
              id
              key
              value
              displayValue
              group
              issue {
                id
                label
                notes
                classifications
              }
              insight {
                id
                label
                notes
                classifications
              }
            }
          }
        }
      `,
      variables: { instanceId, answerId },
      fetchPolicy: 'network-only',
    });

    const formAnswer = response.data.forms.formAnswer;
    if (!formAnswer)
      return {
        isSuccess: false,
        errorReason: 'NOT_FOUND',
      };

    return {
      isSuccess: true,
      data: {
        id: formAnswer.id,
        key: formAnswer.key,
        value: formAnswer.value,
        displayValue: formAnswer.displayValue,
        group: formAnswer.group,
        issue: formAnswer.issue
          ? {
              id: formAnswer.issue.id,
              label: formAnswer.issue.label,
              notes: formAnswer.issue.notes,
              classifications: formAnswer.issue.classifications,
            }
          : undefined,
        insight: formAnswer.insight
          ? {
              id: formAnswer.insight.id,
              label: formAnswer.insight.label,
              notes: formAnswer.insight.notes,
              classifications: formAnswer.insight.classifications,
            }
          : undefined,
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form answer for instance ${instanceId} and answer ${answerId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

import { isAnyOf } from '@reduxjs/toolkit';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { Observable, filter, map } from 'rxjs';
import { insightSlice } from '../../insight/index.ts';
import { issueSlice } from '../../issue/index.ts';
import { formInstanceClearValidationMessage } from './actions.ts';

export function clearFormValidationErrorsOnIssueChanged(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        insightSlice.addClassification.match,
        insightSlice.deleteClassification.match,
        insightSlice.setNotes.match,
        issueSlice.addClassification.match,
        issueSlice.addCoachingIssueActionToIssue.match,
        issueSlice.deleteClassification.match,
        issueSlice.setCoachingConversation.match,
        issueSlice.setNotes.match,
        issueSlice.setPrimaryCauseAdditionalAction.match,
        issueSlice.setPrimaryCauseNoActionsReason.match,
        issueSlice.setPrimaryCause.match,
        issueSlice.setPrimaryCauseNotes.match,
        issueSlice.updateCoachingIssueActionOnIssue.match,
        issueSlice.toggleDefineCauseAndCoachingSelected.match,
      ),
    ),
    map((action) =>
      formInstanceClearValidationMessage(action.payload.instanceId),
    ),
  );
}

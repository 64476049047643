/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';

export type EmptyProps = {
  onReload: () => void;
  reason:
    | 'SUBJECT_UNASSIGNED'
    | 'NO_ISSUES_COACHING_PLAN'
    | 'NO_ISSUES_COACHING_CHECK_IN'
    | 'INSUFFICIENT_PERMISSIONS'
    | 'LOAD_ERROR';
};

export const Empty: React.FC<EmptyProps> = ({ onReload, reason }) => {
  switch (reason) {
    case 'SUBJECT_UNASSIGNED':
      return (
        <div css={styles.base}>
          <Icons.supervisorAccount size={120} fill={'#a0a0a0'} />
          <Text color={color.format(-0.7)} size={18} align={'center'}>
            {`There are no issues to review yet.`}
          </Text>
          <Text color={color.format(-0.7)} size={14} align={'center'}>
            {`Once the coachee is assigned, any issues will show here automatically.`}
          </Text>
        </div>
      );

    case 'NO_ISSUES_COACHING_PLAN':
      return (
        <div css={styles.base}>
          <Icons.clipboardTick size={120} fill={COLORS.GREEN} />
          <Text color={color.format(-0.7)} size={18} align={'center'}>
            {'Great job!'}
          </Text>
          <Text color={color.format(-0.7)} size={14} align={'center'}>
            {'There are no QA issues identified that need coaching.'}
          </Text>
        </div>
      );

    case 'NO_ISSUES_COACHING_CHECK_IN':
      return (
        <div css={styles.base}>
          <Icons.clipboardTick size={120} fill={COLORS.GREEN} />
          <Text color={color.format(-0.7)} size={18} align={'center'}>
            {'Great job!'}
          </Text>
          <Text color={color.format(-0.7)} size={14} align={'center'}>
            {`There were no QA issues identified this period that have needed coaching.`}
          </Text>
        </div>
      );

    case 'LOAD_ERROR':
      return (
        <Button
          style={styles.loadError}
          onClick={onReload}
          hoverAlpha={0.18}
          fill={COLORS.ORANGE_TRANSPARENT}
        >
          <>
            <Icons.contactSupport
              size={95}
              fill={COLORS.ORANGE_LIGHT}
              style={{ paddingBottom: 5 }}
            />
            <Text
              color={COLORS.ORANGE_DARK}
              size={18}
              align={'center'}
              cursor={'inherit'}
            >
              {`We aren't able to show any issues right now.`}
            </Text>
            <Text
              color={COLORS.ORANGE_DARK}
              size={14}
              align={'center'}
              cursor={'inherit'}
            >
              {`Click here to reload. If this problem continues, please try again later.`}
            </Text>
          </>
        </Button>
      );

    case 'INSUFFICIENT_PERMISSIONS':
    default:
      return (
        <div css={styles.base}>
          <Icons.desktopAccessDisabled
            size={120}
            fill={COLORS.RED}
            style={{ paddingBottom: 5 }}
          />
          <Text color={color.format(-0.7)} size={18} align={'center'}>
            {`You don't have permission to see this.`}
          </Text>
          <Text color={color.format(-0.7)} size={14} align={'center'}>
            {`If this is incorrect, check with your administrator.`}
          </Text>
        </div>
      );
  }
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  }),
  loadError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    padding: '30px 0',
    margin: '-30px 0',
    backgroundColor: COLORS.ORANGE_TRANSPARENT,
  } as const,
};

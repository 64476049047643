import { InsightType } from '@se/data/forms/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import {
  IOptionsAnswerLine,
  ISectionLine,
  OptionAnswerInsight,
  OptionAnswerIssue,
} from '../types/types.ts';
import { defaultId, getProp } from '../util/util.data.parse.ts';
import {
  OptionsAnswerDefinition,
  OptionsDefinition,
} from './types/options.types.ts';

export function parseOptionsAnswer(
  item: OptionsDefinition,
  parentSection: ISectionLine,
  indices: number[],
): IOptionsAnswerLine | undefined {
  if (!item) return;

  const question = getProp(item, 'question');

  return {
    id: getProp(item, 'id')?.toString() || defaultId(indices),
    parentId: parentSection.id,
    showOnAnswer: getProp(item, 'showOnAnswer'),
    showOnReportingDateMonth: item.showOnReportingDateMonth,
    showOnSwitch: getProp(item, 'showOnSwitch'),
    group: getProp(item, 'group')?.toString(),
    isRequired: getProp(item, 'isRequired', false),
    isEnabled: getProp(item, 'isEnabled', true),
    highlightOnHover: true,
    type: 'optionsAnswer',
    question,
    options: (getProp(item, 'answers') ?? []).map((option) => ({
      id: option.id?.toString(),
      label: option.label,
      insight: getOptionInsight({ item, parentSection, question, option }),
      issue: getOptionIssue({ item, parentSection, question, option }),
    })),
    direction: getProp(item, 'direction', 'vertical'),
    multiSelect: getProp(item, 'multiSelect', false),
    showIndex: false,
  };
}

type GetOptionIssueArgs = {
  item: OptionsDefinition;
  parentSection: ISectionLine;
  question: string;
  option: OptionsAnswerDefinition;
};

const getOptionIssue = ({
  item,
  parentSection,
  question,
  option,
}: GetOptionIssueArgs): OptionAnswerIssue => {
  const issueDefinition = option.issue;
  if (!issueDefinition) return;

  return {
    classificationDefinition: issueDefinition.classification && {
      label: str.conditionalSuffix(
        issueDefinition.classification.label ?? 'Details:',
        ['?', ':'],
        ':',
      ),
      required: issueDefinition.classification.required,
      options: issueDefinition.classification.options,
    },
    coachingRequired: issueDefinition.coachingRequired,
    coachingRequirement: coachingRequirement(issueDefinition.coachingRequired),
    formAnswerSelected: option.label,
    formLineId: item.id,
    formQuestionText: str.removeMarkdownAndSpecialCharacters(question),
    formSectionName: parentSection.title,
    label: issueDefinition.label,
    notesDefinition: {
      hidden: issueDefinition.notes?.hidden,
      label: issueDefinition.notes?.label ?? 'Reviewer Details',
      required:
        issueDefinition.notes?.required ?? !issueDefinition.notes?.hidden,
    },
    parentSectionLineId: parentSection.id,
  };
};

type GetOptionInsightArgs = {
  item: OptionsDefinition;
  parentSection: ISectionLine;
  question: string;
  option: OptionsAnswerDefinition;
};

const getOptionInsight = ({
  item,
  parentSection,
  question,
  option,
}: GetOptionInsightArgs): OptionAnswerInsight => {
  const insight = option.insight;
  if (!insight) return;

  return {
    classificationDefinition: insight.classification && {
      label: getInsightClassificationLabel(
        insight.type,
        insight.classification.label,
      ),
      required: insight.classification.required,
      options: insight.classification.options,
    },
    formAnswerSelected: option.label,
    formLineId: item.id,
    formQuestionText: str.removeMarkdownAndSpecialCharacters(question),
    formSectionName: parentSection.title,
    label: insight.label,
    notesDefinition: {
      hidden: Boolean(insight.notes?.hidden),
      label: insight.notes?.label ?? 'Details',
      required: insight.notes?.required ?? !insight.notes?.hidden,
    },
    parentSectionLineId: parentSection.id,
    type: insight.type,
  };
};

const coachingRequirement = (coachingRequired: boolean) => {
  if (coachingRequired === true) return 'MANDATORY';
  if (coachingRequired === false) return 'RECOMMENDED';
  return 'NONE';
};

const getInsightClassificationLabel = (type: InsightType, label?: string) => {
  if (label) return str.conditionalSuffix(label, [':', '?'], ':');

  if (type === 'LearningArea') return 'Opportunities to improve:';
  if (type === 'Neutral') return 'Any opportunities to improve?';
  if (type === 'Highlight') return 'Highlights:';
};

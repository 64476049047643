import { DataGridFilter } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType, OrderBy } from '@seeeverything/ui.util/src/types.ts';
import momentTz from 'moment-timezone';
import { FormAction, FormGoal } from './types.ts';

export const isGoal = (record: FormGoal | FormAction): record is FormGoal =>
  (record as FormGoal)?.type === 'FormGoal';

export const isAction = (record: FormGoal | FormAction): record is FormAction =>
  (record as FormAction)?.type === 'FormAction';

export const isChildAction = (record: FormGoal | FormAction): boolean =>
  isAction(record) && Boolean(record.goalId);

export const fromGridSort = (
  sort: DataGridFilter['sort'],
): OrderBy['direction'] => (sort === 'ASC' ? 'Ascending' : 'Descending');

export const toGridSort = (
  sort: OrderBy['direction'],
): DataGridFilter['sort'] => (sort === 'Ascending' ? 'ASC' : 'DESC');

export const createRowData = (
  record: FormGoal | FormAction,
  includeType: boolean,
  timezone: string,
) => {
  const data = [
    record.createdAt &&
      momentTz(record.createdAt).tz(timezone).format('D MMM YYYY'),
    record.description,
    record.assignedTo?.name,
    record.dueBy && momentTz(record.dueBy).tz(timezone).format('D MMM YYYY'),
    record.statusLabel,
  ];

  if (includeType) {
    data.splice(1, 0, record.typeLabel);
  }

  return {
    id: record.id,
    data,
    record,
  };
};

type GridLabelArgs = {
  module: ModuleType;
  dateRangeLabel: string;
  actionLabel: string;
};
export const goalsActionsGridLabel = ({
  module,
  dateRangeLabel,
  actionLabel,
}: GridLabelArgs) => {
  const actionsPart = str.titleCase(str.plural(actionLabel));
  return module === 'coaching'
    ? `Goals and ${actionsPart} - ${dateRangeLabel}`
    : `${actionsPart} - ${dateRangeLabel}`;
};

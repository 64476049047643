import { automatedActionSlice } from './automatedAction/index.ts';
import { automatedActionConfigurationSlice } from './automatedActionConfiguration/index.ts';
import { distributionListSlice } from './distributionList/index.ts';
import { editDistributionListSlice } from './editDistributionList/index.ts';
import { editGoalScheduleSlice } from './editGoalSchedule/index.ts';
import { editInstanceScheduleSlice } from './editInstanceSchedule/index.ts';
import { reducer as formDigitalContentReducer } from './form-digital-content/reducer.ts';
import { formEditGoalSlice } from './form-edit-goal/index.ts';
import { reducer as formFileReducer } from './form-file/reducer.ts';
import { reducer as formInstanceReducer } from './form-instance/reducer.ts';
import { reducer as formScoreReducer } from './form-score/reducer.ts';
import { formActionSlice } from './formAction/index.ts';
import { formActionPlanSlice } from './formActionPlan/index.ts';
import { formBulkUploadClickThroughSlice } from './formBulkUploadClickThrough/index.ts';
import { formBulkUploadDownloadTemplateSlice } from './formBulkUploadDownloadTemplate/index.ts';
import { formBulkUploadImportFileSlice } from './formBulkUploadImportFile/index.ts';
import { formBulkUploadJobHistorySlice } from './formBulkUploadJobHistory/index.ts';
import { appealSlice } from './formInstanceAppeal/index.ts';
import { goalScheduleSlice } from './goalSchedule/index.ts';
import { goalsActionsGridSlice } from './goalsActionsGrid/index.ts';
import { insightSlice } from './insight/index.ts';
import { instanceScheduleSlice } from './instanceSchedule/index.ts';
import { issueSlice } from './issue/index.ts';

export const formsReducers = {
  automatedAction: automatedActionSlice.reducer,
  automatedActionConfiguration: automatedActionConfigurationSlice.reducer,
  editGoalSchedule: editGoalScheduleSlice.reducer,
  editInstanceSchedule: editInstanceScheduleSlice.reducer,
  formActionPlan: formActionPlanSlice.reducer,
  formActionV2: formActionSlice.reducer,
  formBulkUploadClickThrough: formBulkUploadClickThroughSlice.reducer,
  formBulkUploadDownloadTemplate: formBulkUploadDownloadTemplateSlice.reducer,
  formBulkUploadImportFile: formBulkUploadImportFileSlice.reducer,
  formBulkUploadJobHistory: formBulkUploadJobHistorySlice.reducer,
  formDigitalContent: formDigitalContentReducer,
  formDistributionList: distributionListSlice.reducer,
  formEditDistributionList: editDistributionListSlice.reducer,
  formEditGoal: formEditGoalSlice.reducer,
  formFile: formFileReducer,
  formGoalActionsGrid: goalsActionsGridSlice.reducer,
  formInsight: insightSlice.reducer,
  formInstance: formInstanceReducer,
  formInstanceAppeal: appealSlice.reducer,
  formIssue: issueSlice.reducer,
  formScore: formScoreReducer,
  goalSchedule: goalScheduleSlice.reducer,
  instanceSchedule: instanceScheduleSlice.reducer,
};

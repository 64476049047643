import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { FormAnswerAutomatedAction } from '@se/data/forms/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, from, of } from 'rxjs';
import { automatedActionSlice } from '../../automatedAction/index.ts';
import { insightSlice } from '../../insight/index.ts';
import { issueSlice } from '../../issue/index.ts';
import { GlobalFormsState } from '../../store.ts';
import {
  ReduxFormInstanceAnswerChange,
  ReduxFormInstanceReviewerChange,
  ReduxFormInstanceSubjectChange,
} from '../types.ts';
import {
  formInstanceAnswerChangeError,
  formInstanceAnswerChanged,
  formInstanceReviewerChangeError,
  formInstanceReviewerChanged,
  formInstanceSubjectChangeError,
  formInstanceSubjectChanged,
} from './actions.ts';
import { createAnswer, deleteAnswer, updateAnswer } from './mutation.ts';

export const getAnswerChangesObservable = (
  action$: Observable<
    | ReduxFormInstanceAnswerChange
    | ReduxFormInstanceSubjectChange
    | ReduxFormInstanceReviewerChange
  >,
  state$: StateObservable<GlobalFormsState>,
) =>
  action$.pipe(
    ofType(
      'ui.forms/instance/ANSWER/CHANGE',
      'ui.forms/instance/ANSWER/SUBJECT_CHANGE',
      'ui.forms/instance/ANSWER/REVIEWER_CHANGE',
    ),
    filter((action) => {
      const { instanceId, change } = action.payload;

      if (
        change.type !== 'DELETE' &&
        ['reportingDate', 'scoreOverrule'].includes(change.toAnswer.lineId)
      )
        return false;

      if (
        ['UPDATE', 'CREATE'].includes(change.type) &&
        change.toAnswer.value === undefined
      )
        return false;

      return Boolean(state$.value.formInstance.instances[instanceId]);
    }),
  );

export const handleReviewerChangeAction = async (
  action: ReduxFormInstanceReviewerChange,
  client: IGraphQLClient,
) => {
  const { instanceId, change } = action.payload;
  let success = false;

  if (change.type === 'CREATE')
    success = await createAnswer(client, instanceId, change.toAnswer);

  if (change.type === 'UPDATE')
    success = await updateAnswer(client, instanceId, change.toAnswer);

  if (change.type === 'DELETE')
    success = await deleteAnswer(client, instanceId, change.fromAnswer.id);

  return success
    ? of(formInstanceReviewerChanged(instanceId, change))
    : of(formInstanceReviewerChangeError(instanceId, change));
};

export const handleSubjectChangeAction = async (
  action: ReduxFormInstanceSubjectChange,
  client: IGraphQLClient,
) => {
  const { instanceId, change } = action.payload;
  let success = false;

  if (change.type === 'CREATE')
    success = await createAnswer(client, instanceId, change.toAnswer);

  if (change.type === 'UPDATE')
    success = await updateAnswer(client, instanceId, change.toAnswer);

  if (change.type === 'DELETE')
    success = await deleteAnswer(client, instanceId, change.fromAnswer.id);

  return success
    ? of(formInstanceSubjectChanged(instanceId, change))
    : of(formInstanceSubjectChangeError(instanceId, change));
};

const getFormAnswerChangeType = async (
  action: ReduxFormInstanceAnswerChange,
  client: IGraphQLClient,
  instanceId: string,
  answerId: string,
) => {
  if (action.payload.change.type === 'UPDATE') return 'UPDATE';
  if (action.payload.change.type === 'DELETE') return 'DELETE';

  if (!action.payload.change.creationPreviouslyFailed) return 'CREATE';

  const response = await formsQuery.getFormAnswer(client, instanceId, answerId);
  if (response.isSuccess === true) return 'UPDATE';
  return response.errorReason === 'NOT_FOUND' ? 'CREATE' : 'ERROR';
};

export const handleAnswerChangeAction = async (
  action: ReduxFormInstanceAnswerChange,
  client: IGraphQLClient,
  state$: StateObservable<GlobalFormsState>,
) => {
  const { instanceId, change, revertStateOnError } = action.payload;
  let success = false;
  let issueId: string | undefined;
  let insightId: string | undefined;
  let automatedActions: FormAnswerAutomatedAction[];

  const changeType = await getFormAnswerChangeType(
    action,
    client,
    instanceId,
    change.toAnswer?.id,
  );

  if (changeType === 'ERROR') success = false;

  if (changeType === 'CREATE' && !change.postChangeQueryIssueInsightId)
    success = await createAnswer(client, instanceId, change.toAnswer);

  if (changeType === 'CREATE' && change.postChangeQueryIssueInsightId) {
    const response = await formsMutation.createFormAnswerWithIssueInsight(
      client,
      {
        instanceId,
        answerId: change.toAnswer.id,
        answerKey: change.toAnswer.lineId,
        answerValue: change.toAnswer.value,
        answerDisplayValue: change.toAnswer.displayValue,
        answerGroup: change.toAnswer.group,
        questionKeys: state$.value.formInstance.questionKeys,
        issueInsightAnswerDefinitions:
          state$.value.formInstance.issueInsightAnswerDefinitions,
      },
    );
    success = response.isSuccess;
    issueId = response.data?.issueId;
    insightId = response.data?.insightId;
    automatedActions = response.data?.answerAutomatedActions;
  }

  if (changeType === 'UPDATE' && !change.postChangeQueryIssueInsightId)
    success = await updateAnswer(client, instanceId, change.toAnswer);

  if (changeType === 'UPDATE' && change.postChangeQueryIssueInsightId) {
    const response = await formsMutation.updateFormAnswerWithIssueInsight(
      client,
      {
        instanceId,
        answerId: change.toAnswer.id,
        answerValue: change.toAnswer.value,
        answerDisplayValue: change.toAnswer.displayValue,
        questionKeys: state$.value.formInstance.questionKeys,
        issueInsightAnswerDefinitions:
          state$.value.formInstance.issueInsightAnswerDefinitions,
      },
    );
    success = response.isSuccess;
    issueId = response.data?.issueId;
    insightId = response.data?.insightId;
    automatedActions = response.data?.answerAutomatedActions;
  }

  if (changeType === 'DELETE')
    success = await deleteAnswer(client, instanceId, change.fromAnswer.id);

  if (!success)
    return of(
      formInstanceAnswerChangeError(instanceId, change, revertStateOnError),
    );

  return from(
    [
      formInstanceAnswerChanged(instanceId, change),
      issueId && change.toAnswer
        ? issueSlice.answerChangedWithNewIssueId({
            issueId,
            instanceId,
            toAnswer: change.toAnswer,
          })
        : undefined,
      insightId && change.toAnswer
        ? insightSlice.answerChangedWithNewInsightId({
            insightId,
            instanceId,
            toAnswer: change.toAnswer,
          })
        : undefined,
      automatedActions
        ? automatedActionSlice.setAnswerAutomatedActions({
            answerId: change.toAnswer.id,
            automatedActions,
          })
        : undefined,
    ].filter(Boolean),
  );
};

/** @jsxImportSource @emotion/react */
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { is } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { SelectAnswerOptionDefinition } from '../../parse/types/input.types.ts';
import { formInstanceAnswerChange } from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, ISelectAnswerLine } from '../../types/types.ts';
import { PreviousResponsesDropdown } from '../PreviousAnswersDropdown/PreviousAnswersDropdown.tsx';
import { SelectAnswer } from '../SelectAnswer/SelectAnswer.tsx';
import { usePreviousAnswers } from './usePreviousAnswers.ts';

export interface ISelectAnswerContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  isVisible: boolean;
  line: ISelectAnswerLine;
}

export const SelectAnswerContainer: React.FC<ISelectAnswerContainerProps> = ({
  canUpdateInstance,
  instanceId,
  isPdfExport,
  isVisible,
  line,
}) => {
  const dispatch = useFormsDispatch();
  const { client } = useGraphQL();
  const { track } = useSegmentAnalytics();

  const [requestPreviousAnswers, setRequestPreviousAnswers] = useState(false);

  const trackShowPreviousAnswers = useCallback(() => {
    track('form_view_previous_answer', { formInstanceId: instanceId });
    setRequestPreviousAnswers(true);
  }, [instanceId, track]);

  const instanceSubjectId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject?.id,
  );

  const instanceTemplateId = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.template?.id,
  );

  const previousAnswersDefinition = line.previousAnswers;

  const previousAnswersEnabled = Boolean(
    previousAnswersDefinition?.isEnabled && !isPdfExport,
  );

  const { previousAnswers, isFetchingPreviousAnswers } = usePreviousAnswers(
    client,
    {
      instanceId,
      subjectId: instanceSubjectId,
      templateId: instanceTemplateId,
      inputId: line.id,
      fromDifferentTemplateInputId:
        previousAnswersDefinition?.fromDifferentTemplate?.inputId,
      fromDifferentTemplateTemplateName:
        previousAnswersDefinition?.fromDifferentTemplate?.template,
      previousAnswersEnabled: previousAnswersEnabled && requestPreviousAnswers,
    },
  );

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValueId = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id]?.value?.toString();
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      if (!isVisible) return;

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: to.id.toString(),
        lineId: line.id,
        type: 'inputs',
        subType: 'dropdownAnswer',
        displayValue: to.content?.text?.toString(),
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  const selections = useMemo(
    () =>
      line.options.map((option) =>
        isRichSelectOption(option)
          ? {
              id: option.id ?? option.label,
              icon: option.icon,
              content: {
                text: option.label ?? option.id,
                description: option.description,
              },
              value: option.id ?? option.label,
            }
          : { id: option, content: { text: option }, value: option },
      ),
    [line.options],
  );

  const elSelectAnswer = (
    <SelectAnswer
      error={error}
      floatingText={line.floatingText}
      helperText={line.helperText}
      id={line.id}
      isEnabled={canUpdateInstance}
      onChange={updateAnswer}
      renderText={isPdfExport}
      selections={selections}
      value={answerValueId}
    />
  );

  return previousAnswersEnabled ? (
    <PreviousResponsesDropdown
      dropdownTitle={'Previous Responses'}
      isLoading={isFetchingPreviousAnswers}
      linkLabel={
        previousAnswersDefinition.fromDifferentTemplate?.template
          ? `Show Previous ${previousAnswersDefinition.fromDifferentTemplate?.template} Responses`
          : 'Show Previous Responses'
      }
      emptyMessage={'No previous responses to display.'}
      onAnswersDropdownShow={trackShowPreviousAnswers}
      previousResponses={previousAnswers}
    >
      {elSelectAnswer}
    </PreviousResponsesDropdown>
  ) : (
    elSelectAnswer
  );
};

const isRichSelectOption = (
  option: string | SelectAnswerOptionDefinition,
): option is SelectAnswerOptionDefinition => !is(String, option);

import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { mapAutomatedAction } from '../query/automatedActionsUtil.ts';
import {
  FormAnswerAutomatedAction,
  FormIssueInsightAnswerDefinition,
  ServerAutomatedAction,
} from '../types.ts';

export type MutateAnswerWithIssueInsightDto = {
  issueId?: string;
  insightId?: string;
  answerAutomatedActions?: FormAnswerAutomatedAction[];
};

type CreateFormAnswerWithIssueInsightArgs = {
  instanceId: string;
  answerId: string;
  answerKey: string;
  answerValue: string;
  answerDisplayValue?: string;
  answerGroup?: string;
  questionKeys: string[];
  issueInsightAnswerDefinitions: Record<
    string,
    FormIssueInsightAnswerDefinition[]
  >;
};

export const createFormAnswerWithIssueInsight = async (
  client: IGraphQLClient,
  args: CreateFormAnswerWithIssueInsightArgs,
): Promise<MutationResult<MutateAnswerWithIssueInsightDto>> => {
  try {
    const response = await client.mutate<{
      forms: {
        createFormAnswerOnFormInstance: {
          ok: boolean;
          answer?: {
            issue?: { id: string };
            insight?: { id: string };
            automatedActions?: ServerAutomatedAction[];
          };
        };
      };
    }>({
      mutation: gql`
        mutation CreateFormAnswerWithIssueInsightQuery(
          $instanceId: ID!
          $answerId: ID!
          $answerKey: String!
          $value: String!
          $displayValue: String
          $group: String
        ) {
          forms {
            createFormAnswerOnFormInstance(
              input: {
                formInstanceId: $instanceId
                formAnswerId: $answerId
                key: $answerKey
                value: $value
                displayValue: $displayValue
                group: $group
              }
            ) {
              ok
              answer {
                issue {
                  id
                }
                insight {
                  id
                }
                automatedActions {
                  id
                  description
                  label
                  createdActionId
                  automatedAction {
                    matchInsightClassification
                    matchIssueClassification
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        instanceId: args.instanceId,
        answerId: args.answerId,
        answerKey: args.answerKey,
        value: args.answerValue,
        displayValue: args.answerDisplayValue,
        group: args.answerGroup,
      },
    });

    if (!response.data.forms.createFormAnswerOnFormInstance.ok)
      throw new Error('Not ok');

    const answer = response.data.forms.createFormAnswerOnFormInstance.answer;

    const issueId = answer.issue?.id;
    const insightId = answer.insight?.id;
    const answerAutomatedActions = answer.automatedActions;

    return {
      isSuccess: true,
      data: {
        issueId,
        insightId,
        answerAutomatedActions: (answerAutomatedActions ?? []).map(
          (automatedAction) =>
            mapAutomatedAction({
              answerKey: args.answerKey,
              answerValue: args.answerValue,
              input: automatedAction,
              issueInsightAnswerDefinitions: args.issueInsightAnswerDefinitions,
              issueId,
              insightId,
              questionKeys: args.questionKeys,
            }),
        ),
      },
    };
  } catch (err) {
    log.error(
      `Unable to create answer with issue for instance ${args.instanceId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

type UpdateFormAnswerWithIssueInsightArgs = {
  instanceId: string;
  answerId: string;
  answerValue: string;
  answerDisplayValue?: string;
  questionKeys: string[];
  issueInsightAnswerDefinitions: Record<
    string,
    FormIssueInsightAnswerDefinition[]
  >;
};

export const updateFormAnswerWithIssueInsight = async (
  client: IGraphQLClient,
  args: UpdateFormAnswerWithIssueInsightArgs,
): Promise<MutationResult<MutateAnswerWithIssueInsightDto>> => {
  try {
    const response = await client.mutate<{
      forms: {
        updateFormAnswerValueOnFormInstance: {
          ok: boolean;
          answer?: {
            key: string;
            issue?: { id: string };
            insight?: { id: string };
            automatedActions?: ServerAutomatedAction[];
          };
        };
      };
    }>({
      mutation: gql`
        mutation UpdateFormAnswerWithIssueInsightQuery(
          $instanceId: ID!
          $answerId: ID!
          $value: String!
          $displayValue: String
        ) {
          forms {
            updateFormAnswerValueOnFormInstance(
              input: {
                formInstanceId: $instanceId
                formAnswerId: $answerId
                value: $value
                displayValue: $displayValue
              }
            ) {
              ok
              answer {
                key
                issue {
                  id
                }
                insight {
                  id
                }
                automatedActions {
                  id
                  description
                  label
                  createdActionId
                  automatedAction {
                    matchInsightClassification
                    matchIssueClassification
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        instanceId: args.instanceId,
        answerId: args.answerId,
        value: args.answerValue,
        displayValue: args.answerDisplayValue,
      },
    });

    if (!response.data.forms.updateFormAnswerValueOnFormInstance.ok)
      throw new Error('Not ok');

    const answer =
      response.data.forms.updateFormAnswerValueOnFormInstance.answer;

    return {
      isSuccess: true,
      data: {
        issueId: answer.issue?.id,
        insightId: answer.insight?.id,
        answerAutomatedActions: (answer.automatedActions ?? []).map(
          (automatedAction) =>
            mapAutomatedAction({
              answerKey: answer.key,
              answerValue: args.answerValue,
              input: automatedAction,
              issueInsightAnswerDefinitions: args.issueInsightAnswerDefinitions,
              issueId: answer.issue?.id,
              insightId: answer.insight?.id,
              questionKeys: args.questionKeys,
            }),
        ),
      },
    };
  } catch (err) {
    log.error(
      `Problem updating answer ${args.answerId} for instance ${args.instanceId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};
